import { useMemo } from 'react'

import { LedgerTransactionStatus } from '@publica/lookups'
import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'

import { LedgerAssetHoldershipLabel, UncommittedValue } from '../'

export type LedgerDismemberment = {
    usufructHolder: {
        beneficiary: {
            legalEntity: {
                name: string
            }
        }
    }
    bareOwnershipHolder: {
        beneficiary: {
            legalEntity: {
                name: string
            }
        }
    }
    transaction: {
        sequence?: number | null
    }
}

type LedgerBalanceOriginLabelProps = {
    origin: {
        historical: boolean
        status: LedgerTransactionStatus
    } & (
        | { holdershipType: 'FULL_OWNERSHIP' }
        | {
              holdershipType: 'BARE_OWNERSHIP' | 'USUFRUCT'
              dismemberment: LedgerDismemberment
          }
    )
}

export const LedgerBalanceOriginLabel: FC<LedgerBalanceOriginLabelProps> = ({ origin }) => {
    const { t } = useLedgerBalanceOriginLabelTranslation()
    const holdership = useMemo(() => <LedgerAssetHoldershipLabel {...origin} />, [origin])

    const key = origin.historical ? 'historical' : 'register'

    if (origin.status === 'COMMITTED') {
        return (
            <>
                {t(key)} {holdership}
            </>
        )
    }

    return (
        <>
            {t(key)} ({holdership}) <UncommittedValue>({t('uncommitted')})</UncommittedValue>
        </>
    )
}

const useLedgerBalanceOriginLabelTranslation = createUseTranslation({
    FR: {
        historical: `Reprise de l'existant`,
        register: 'Registre',
        uncommitted: 'non-confirmé',
    },
    EN: {
        historical: `Historical holdership`,
        register: 'Ledger',
        uncommitted: 'uncommitted',
    },
})
