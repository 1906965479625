import { InternalRefetchQueriesInclude } from '@apollo/client'
import { Form, FormInstance, Select } from 'antd'
import { useCallback, useMemo } from 'react'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import {
    ControlledForm,
    FormRules,
    FormValuesWithRequired,
    useCommonRules,
    useConnectControlledForm,
} from '@publica/ui-web-utils'

import { LedgerAccountType, useCreateConversionLedgerTransactionMutation } from '../../../../../../data'
import { AmountInput, LedgerAccountSearch, LedgerAssetTypeSelect } from '../../../../../components'
import { useDistinctLedgerAssetTypeValidators } from '../../../../../lib'
import {
    DebitableLedgerAssetType,
    DebitableLedgerAssetTypesSelect,
    TransactionContextForm,
    TransactionContextFormValues,
    useTransactionFormTranslations,
} from '../components'

type ConversionFormProps = {
    ledgerId: string
    form: ControlledForm<string | undefined, ConversionFormValues, ConversionFormRequiredValues>
    refetchQueries?: InternalRefetchQueriesInclude
}

type ConversionFormValues = {
    sourceAmount: number | undefined
    creditorAccount: { id: string } | undefined
    sourceAssetType: DebitableLedgerAssetType | undefined
    targetAssetType: { id: string } | undefined
    ratio: number | undefined
} & TransactionContextFormValues

type ConversionFormRequiredValues = FormValuesWithRequired<
    ConversionFormValues,
    'creditorAccount' | 'occurredAt' | 'ratio' | 'sourceAmount' | 'sourceAssetType' | 'targetAssetType'
>

export const ConversionForm: FC<ConversionFormProps> = ({ ledgerId, form, refetchQueries }) => {
    const [createConversionLedgerTransaction, { loading }] = useCreateConversionLedgerTransactionMutation()

    const submit = useCallback(
        async (values: ConversionFormRequiredValues): Promise<string | undefined> => {
            const { occurredAt, comment, sourceAmount, creditorAccount, sourceAssetType, targetAssetType, ratio } =
                values

            const transaction = {
                context: {
                    occurredAt,
                    comment,
                },
                sourceAmount,
                creditorAccountId: creditorAccount.id,
                sourceAssetTypeId: sourceAssetType.assetTypeId,
                targetAssetTypeId: targetAssetType.id,
                ratio,
            }

            return createConversionLedgerTransaction({
                variables: {
                    ledgerId,
                    transaction,
                },
                refetchQueries,
            }).then(({ data }) => data?.createConversionLedgerTransaction.id)
        },
        [createConversionLedgerTransaction, ledgerId, refetchQueries]
    )

    useConnectControlledForm(form, submit, loading)

    return <ConversionTransactionForm ledgerId={ledgerId} form={form.form} />
}

type ConversionTransactionFormProps = {
    ledgerId: string
    form: FormInstance<ConversionFormValues>
}

// TODO(ledger-ui, transactions): auto-fill amount

const ConversionTransactionForm: FC<ConversionTransactionFormProps> = ({ ledgerId, form }) => {
    const rules = useCommonRules()
    const { t } = useTransactionFormTranslations()
    const { t: t2 } = useConversionTransactionFormTranslations()

    const [sourceAssetTypeValidator, targetAssetTypeValidator] = useDistinctLedgerAssetTypeValidators(
        'sourceAssetType',
        'targetAssetType',
        {
            aTransform: (a: DebitableLedgerAssetType) => ({
                id: a.assetTypeId,
            }),
        }
    )

    const creditorAccount = Form.useWatch('creditorAccount', form)
    const sourceAssetType = Form.useWatch('sourceAssetType', form)
    const amountDisabled = sourceAssetType === undefined

    const validation = useMemo<FormRules<ConversionFormValues>>(
        () => ({
            creditorAccount: rules.required,
            sourceAmount: [
                ...rules.required,
                {
                    validator: async (_, value: number) => {
                        if (value > (sourceAssetType?.amount ?? 0)) {
                            throw new Error(t('insufficientBalance'))
                        }
                    },
                },
            ],
            ratio: rules.required,
            sourceAssetType: [...rules.required, sourceAssetTypeValidator],
            targetAssetType: [...rules.required, targetAssetTypeValidator],
        }),
        [rules.required, sourceAssetType?.amount, sourceAssetTypeValidator, t, targetAssetTypeValidator]
    )

    return (
        <Form form={form} layout="vertical">
            <TransactionContextForm>
                <Form.Item name="creditorAccount" label={t('creditorAccount')} rules={validation.creditorAccount}>
                    <LedgerAccountSearch ledgerAccountTypes={conversionLedgerAccountTypes} ledgerId={ledgerId} />
                </Form.Item>
                <Form.Item name="sourceAssetType" label={t2('sourceAssetType')} rules={validation.sourceAssetType}>
                    {creditorAccount?.id === undefined ? (
                        <Select disabled />
                    ) : (
                        <DebitableLedgerAssetTypesSelect
                            ledgerId={ledgerId}
                            ledgerAccountId={creditorAccount.id}
                            holdershipTypes={'FULL_OWNERSHIP'}
                        />
                    )}
                </Form.Item>
                <Form.Item name="sourceAmount" label={t('amount')} rules={validation.sourceAmount}>
                    <AmountInput disabled={amountDisabled} />
                </Form.Item>
                <Form.Item name="targetAssetType" label={t2('targetAssetType')} rules={validation.targetAssetType}>
                    <LedgerAssetTypeSelect ledgerId={ledgerId} />
                </Form.Item>
                <Form.Item name="ratio" label={t2('ratio')} rules={validation.ratio}>
                    <AmountInput />
                </Form.Item>
            </TransactionContextForm>
        </Form>
    )
}

const conversionLedgerAccountTypes: LedgerAccountType[] = ['STANDARD']

const useConversionTransactionFormTranslations = createUseTranslation({
    FR: {
        sourceAssetType: 'Actif source',
        targetAssetType: 'Actif cible',
        ratio: 'Taux de conversion',
    },
    EN: {
        sourceAssetType: 'Source asset type',
        targetAssetType: 'Target asset type',
        ratio: 'Conversion ratio',
    },
})
