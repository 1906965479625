import { Select } from 'antd'
import flatMap from 'lodash/flatMap'
import isArray from 'lodash/isArray'
import { ReactNode, useCallback, useMemo } from 'react'

import { FC } from '@publica/ui-common-utils'
import { buildIdMap } from '@publica/utils'

import { LedgerAccountType, useGetLedgerAccountBalancesQuery } from '../../../../../../../data'
import { LedgerBalanceLabel } from '../../../../../../components/LedgerBalanceLabel'

type DebitableLedgerAssetTypesSelectProps = {
    ledgerId: string
    ledgerAccountId: string
    holdershipTypes?: DebitableHoldershipTypes
    onChange?: (value: DebitableLedgerAssetType | undefined) => void
    value?: DebitableLedgerAssetType
}

export type DebitableHoldershipTypes = 'FULL_OWNERSHIP' | 'DISMEMBERED' | 'ALL'

type OptionType = {
    label: ReactNode
    value: string
}

export type DebitableLedgerAssetType = {
    id: string
    assetTypeId: string
    eligibleAccountTypes: LedgerAccountType[]
    name: ReactNode
    amount: number
} & (
    | {
          type: 'DISMEMBERED'
          dismemberment: {
              holdershipType: 'USUFRUCT' | 'BARE_OWNERSHIP'
              transactionId: string
          }
      }
    | {
          type: 'FULL_OWNERSHIP'
      }
)

export const DebitableLedgerAssetTypesSelect: FC<DebitableLedgerAssetTypesSelectProps> = ({
    ledgerAccountId,
    ledgerId,
    onChange,
    holdershipTypes = 'ALL',
}) => {
    const { loading, data } = useGetLedgerAccountBalancesQuery({
        variables: {
            ledgerId,
            ledgerAccountId,
        },
        fetchPolicy: 'no-cache',
    })

    const includeFullOwnership = holdershipTypes === 'ALL' || holdershipTypes === 'FULL_OWNERSHIP'
    const includeDismembered = holdershipTypes === 'ALL' || holdershipTypes === 'DISMEMBERED'

    const assetTypeBalances = useMemo(
        () =>
            flatMap(data?.ledger?.account.balance.balanceByAssetType ?? [], (balance): DebitableLedgerAssetType[] => {
                const assetTypeBalances: DebitableLedgerAssetType[] = []
                const { assetType, balanceByHoldership } = balance

                const { id: assetTypeId, eligibleAccountTypes, name } = assetType

                if (includeFullOwnership && balanceByHoldership.fullOwnership.amount.uncommittedAmount > 0) {
                    const amount = balanceByHoldership.fullOwnership.amount.uncommittedAmount

                    assetTypeBalances.push({
                        id: balanceByHoldership.fullOwnership.id,
                        assetTypeId,
                        eligibleAccountTypes,
                        amount,
                        name: <LedgerBalanceLabel name={name} amount={amount} holdershipType="FULL_OWNERSHIP" />,
                        type: 'FULL_OWNERSHIP',
                    })
                }

                if (includeDismembered) {
                    for (const [property, type] of [
                        ['usufruct', 'USUFRUCT'],
                        ['bareOwnership', 'BARE_OWNERSHIP'],
                    ] as const) {
                        const balance = balanceByHoldership[property]
                        if (balance.amount.uncommittedAmount <= 0) {
                            continue
                        }

                        for (const origin of balance.origins) {
                            if (origin.__typename === 'LedgerDismemberedBalanceOrigin') {
                                const { dismemberment } = origin
                                const amount = origin.amount.uncommittedAmount

                                assetTypeBalances.push({
                                    id: origin.id,
                                    assetTypeId,
                                    eligibleAccountTypes,
                                    amount,
                                    name: (
                                        <LedgerBalanceLabel
                                            name={name}
                                            amount={amount}
                                            holdershipType={type}
                                            dismemberment={dismemberment}
                                        />
                                    ),
                                    type: 'DISMEMBERED',
                                    dismemberment: {
                                        holdershipType: type,
                                        transactionId: dismemberment.transaction.id,
                                    },
                                })
                            }
                        }
                    }
                }

                return assetTypeBalances
            }),
        [data?.ledger?.account.balance.balanceByAssetType, includeDismembered, includeFullOwnership]
    )

    const assetTypeBalancesMap = useMemo(() => buildIdMap(assetTypeBalances), [assetTypeBalances])

    const options = useMemo(
        () =>
            assetTypeBalances.map(balance => ({
                label: balance.name,
                value: balance.id,
            })),
        [assetTypeBalances]
    )

    const onSelectBalance = useCallback(
        (_: OptionType, option: OptionType | OptionType[]) => {
            if (!isArray(option)) {
                const assetTypeBalance = assetTypeBalancesMap[option.value]!
                onChange?.(assetTypeBalance)
            }
        },
        [assetTypeBalancesMap, onChange]
    )

    return <Select loading={loading} options={options} onChange={onSelectBalance} popupMatchSelectWidth={false} />
}
