import { InternalRefetchQueriesInclude } from '@apollo/client'
import { Form, Input } from 'antd'
import { useCallback, useMemo } from 'react'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import {
    ControlledForm,
    ControlledFormWithElement,
    FormRules,
    useCommonRules,
    useControlledForm,
} from '@publica/ui-web-utils'
import { assert } from '@publica/utils'

import { useCreateLedgerAssetTypeMutation, useGetLedgerAssetTypesLazyQuery } from '../../../../../data'

type NewLedgerAssetTypeFormProps = {
    ledgerId: string
    form: ControlledForm<string | undefined, NewLedgerAssetTypeFormValues>
}

const NewLedgerAssetTypeForm: FC<NewLedgerAssetTypeFormProps> = ({ form, ledgerId }) => {
    const { t } = useNewLedgerAssetTypeTranslation()
    const rules = useCommonRules()

    const [getLedgerAssetTypes] = useGetLedgerAssetTypesLazyQuery()

    const validationRules = useMemo<FormRules<NewLedgerAssetTypeFormValues>>(
        () => ({
            name: [
                ...rules.required,
                {
                    validator: async (_, value: string | undefined) => {
                        if (value === undefined) {
                            return
                        }

                        const existingAssetTypes = await getLedgerAssetTypes({
                            variables: {
                                ledgerId,
                            },
                            fetchPolicy: 'no-cache',
                        })

                        const assetName = value.toLowerCase().trim()

                        for (const assetType of existingAssetTypes.data?.ledger?.assetTypes ?? []) {
                            if (assetType.name.toLowerCase() === assetName) {
                                throw new Error(t('alreadyExists'))
                            }
                        }
                    },
                },
            ],
        }),
        [getLedgerAssetTypes, ledgerId, rules.required, t]
    )

    return (
        <Form form={form.form} layout="vertical">
            <Form.Item name="name" label={t('name')} hasFeedback rules={validationRules.name}>
                <Input />
            </Form.Item>
        </Form>
    )
}

const useNewLedgerAssetTypeTranslation = createUseTranslation({
    FR: {
        name: `Nom de l'actif`,
        alreadyExists: `Il existe déjà un actif portant ce nom`,
    },
    EN: {
        name: 'Asset name',
        alreadyExists: 'There is already an asset type with this name',
    },
})

type NewLedgerAssetTypeFormValues = {
    name: string | undefined
}

type NewLedgerAssetTypeFormOptions = {
    refetchQueries?: InternalRefetchQueriesInclude
}

export const useNewLedgerAssetTypeForm = (
    ledgerId: string,
    options?: NewLedgerAssetTypeFormOptions
): ControlledFormWithElement<string | undefined, NewLedgerAssetTypeFormValues> => {
    const [createLedgerAssetType, { loading }] = useCreateLedgerAssetTypeMutation()

    const submit = useCallback(
        async (values: NewLedgerAssetTypeFormValues) => {
            const { name } = values

            assert.defined(name)

            return createLedgerAssetType({
                variables: {
                    ledgerId,
                    name,
                },
                refetchQueries: options?.refetchQueries,
            }).then(({ data }) => data?.createLedgerAssetType.id)
        },
        [createLedgerAssetType, ledgerId, options?.refetchQueries]
    )

    return useControlledForm({
        render: form => <NewLedgerAssetTypeForm form={form} ledgerId={ledgerId} />,
        submit,
        loading,
    })
}
