import { ColumnType } from 'antd/lib/table'
import { useMemo } from 'react'

import { createUseTranslation } from '@publica/ui-common-i18n'

import { LedgerBalanceAmount } from './LedgerBalanceAmount'
import { LedgerAssetType } from './types'

type HasBalance = {
    balance: {
        amount: {
            committedAmount: number
            uncommittedAmount: number
        }
        asPercentOfLedgerTotal: {
            committedAmount: number
            uncommittedAmount: number
        }
    }
}

export const useCapitalAmountColumns = <T extends HasBalance>() => {
    const { t } = useAmountColumnsTranslation()

    return useMemo<ColumnType<T>[]>(
        () => [
            {
                title: t('capital'),
                children: [
                    {
                        title: t('holding'),
                        render: (_, o) => <LedgerBalanceAmount amount={o.balance.amount} />,
                    } as ColumnType<T>,
                    {
                        title: t('holdingPercentage'),
                        render: (_, o) => (
                            <LedgerBalanceAmount amount={o.balance.asPercentOfLedgerTotal} mode="percentage" />
                        ),
                    } as ColumnType<T>,
                ],
            },
        ],
        [t]
    )
}

const useAmountColumnsTranslation = createUseTranslation({
    FR: {
        holding: 'Détention',
        holdingPercentage: 'Détention (%)',
        capital: 'Capital Social',
    },
    EN: {
        holding: 'Holdership',
        holdingPercentage: 'Holdership (%)',
        capital: 'Capital',
    },
})

type HasAssetTypeBalance = {
    balance: {
        balanceByAssetType: {
            assetType: {
                id: string
            }
            amount: {
                uncommittedAmount: number
                committedAmount: number
            }
            asPercentOfLedgerTotal: {
                committedAmount: number
                uncommittedAmount: number
            }
        }[]
    }
}

export const useAssetTypeAmountsColumns = <T extends HasAssetTypeBalance>(ledgerAssetTypes: LedgerAssetType[]) => {
    const { t } = useAmountColumnsTranslation()

    return useMemo<ColumnType<T>[]>(
        () =>
            ledgerAssetTypes.map(ledgerAssetType => {
                return {
                    title: ledgerAssetType.name,
                    children: [
                        {
                            title: t('holding'),
                            render: (_, group) => {
                                const assetBalance = group.balance.balanceByAssetType.find(
                                    assetBalance => assetBalance.assetType.id === ledgerAssetType.id
                                )
                                return <LedgerBalanceAmount amount={assetBalance?.amount} />
                            },
                            align: 'right',
                            width: 250,
                        } as ColumnType<T>,
                        {
                            title: t('holdingPercentage'),
                            render: (_, group) => {
                                const assetBalance = group.balance.balanceByAssetType.find(
                                    assetBalance => assetBalance.assetType.id === ledgerAssetType.id
                                )

                                return (
                                    <LedgerBalanceAmount
                                        amount={assetBalance?.asPercentOfLedgerTotal}
                                        mode="percentage"
                                    />
                                )
                            },
                            align: 'right',
                        } as ColumnType<T>,
                    ],
                }
            }),
        [ledgerAssetTypes, t]
    )
}
