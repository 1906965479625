import { Form, Select } from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import { useEffect, useMemo } from 'react'

import { FC } from '@publica/ui-common-utils'
import { FormRules, useCommonRules } from '@publica/ui-web-utils'

import { AmountInput } from '../../../../../../components'
import { useLedgerAssetTypeEligibilityValidator } from '../../../../../../lib'
import {
    DebitableHoldershipTypes,
    DebitableLedgerAssetType,
    DebitableLedgerAssetTypesSelect,
} from '../DebitableLedgerAssetTypesSelect'
import { useTransactionFormTranslations } from '../common'

export type DebitAssetTypeFormProps = {
    ledgerId: string
    debitorAccountId: string | undefined
    form: FormInstance<DebitAssetTypeFormValues>
    holdershipTypes?: DebitableHoldershipTypes
    creditorAccountField?: string
}

export type DebitAssetTypeFormValues = {
    amount: number | undefined
    assetType: DebitableLedgerAssetType | undefined
}

export const DebitAssetTypeForm: FC<DebitAssetTypeFormProps> = ({
    ledgerId,
    debitorAccountId,
    form,
    holdershipTypes,
    creditorAccountField,
}) => {
    const { t } = useTransactionFormTranslations()
    const rules = useCommonRules()

    const assetType = Form.useWatch('assetType', form)
    const amountDisabled = assetType === undefined

    const assetTypeValidator = useLedgerAssetTypeEligibilityValidator(creditorAccountField ?? 'unknown')
    const creditorAccount = Form.useWatch(creditorAccountField ?? '')

    useEffect(() => {
        if (assetType !== undefined) {
            void form.validateFields(['assetType'])
        }
    }, [assetType, creditorAccount, form])

    useEffect(() => {
        if (assetType !== undefined) {
            form.setFieldValue('amount', assetType.amount)
        }
    }, [assetType, form])

    const validation = useMemo<FormRules<DebitAssetTypeFormValues>>(
        () => ({
            amount: [
                ...rules.required,
                {
                    validator: async (_, value: number) => {
                        if (value > (assetType?.amount ?? 0)) {
                            throw new Error(t('insufficientBalance'))
                        }
                    },
                },
            ],
            assetType: [...rules.required, assetTypeValidator],
        }),
        [assetType?.amount, assetTypeValidator, rules.required, t]
    )

    return (
        <>
            <Form.Item name="assetType" label={t('assetType')} rules={validation.assetType} hasFeedback>
                {debitorAccountId === undefined ? (
                    <Select disabled />
                ) : (
                    <DebitableLedgerAssetTypesSelect
                        holdershipTypes={holdershipTypes}
                        ledgerId={ledgerId}
                        ledgerAccountId={debitorAccountId}
                    />
                )}
            </Form.Item>
            <Form.Item name="amount" label={t('amount')} rules={validation.amount} hasFeedback>
                <AmountInput disabled={amountDisabled} />
            </Form.Item>
        </>
    )
}
