import isNil from 'lodash/isNil'
import { ReactNode, useMemo } from 'react'

import { NotFound } from '../NotFound'
import { Spinner } from '../Spinner'

type PendingProps<R> = {
    loading: boolean
    record: R
    render: (record: NonNullable<R>) => ReactNode
    notFoundText: string
    notFoundPath?: string
}

export const Pending = <R,>({ loading, record, notFoundText, notFoundPath = '..', render }: PendingProps<R>) => {
    const content = useMemo(() => {
        if (loading) {
            return <Spinner />
        }

        if (isNil(record)) {
            return <NotFound title={notFoundText} path={notFoundPath} card />
        }

        return render(record as NonNullable<R>)
    }, [loading, notFoundPath, notFoundText, record, render])

    return <>{content}</>
}
