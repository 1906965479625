import { Form } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { DateTime } from 'luxon'
import { CSSProperties, useMemo } from 'react'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { DatePicker } from '@publica/ui-web-components'
import { useCommonRules } from '@publica/ui-web-utils'

export type TransactionContextFormValues = {
    occurredAt: DateTime | undefined
    comment?: string
}

export const TransactionContextForm: FC = ({ children }) => {
    const rules = useCommonRules()
    const { t } = useTransactionContextFormTranslation()

    const initialValue = useMemo(() => DateTime.now(), [])

    return (
        <>
            <Form.Item
                name="occurredAt"
                label={t('occurredAt')}
                initialValue={initialValue}
                hasFeedback
                rules={rules.required}
            >
                <DatePicker style={occurredAtStyle} />
            </Form.Item>
            {children}
            <Form.Item name="comment" label={t('comment')}>
                <TextArea />
            </Form.Item>
        </>
    )
}

const occurredAtStyle: CSSProperties = {
    width: '100%',
}

const useTransactionContextFormTranslation = createUseTranslation({
    FR: {
        occurredAt: 'Date de la transaction',
        comment: 'Commentaire',
        historical: `Reprise de l'existant`,
    },
    EN: {
        occurredAt: 'Transaction date',
        comment: 'Comment',
        historical: 'Historical transaction',
    },
})
