import { createUseStyles } from 'react-jss'

import { colors } from '@publica/ui-common-styles'
import { FC } from '@publica/ui-common-utils'

const useUncommittedValueStyles = createUseStyles({
    value: {
        color: colors.grey7,
    },
})

export const UncommittedValue: FC = ({ children }) => {
    const styles = useUncommittedValueStyles()

    return <i className={styles.value}>{children}</i>
}
