import { InternalRefetchQueriesInclude } from '@apollo/client'
import { Form, FormInstance } from 'antd'
import { useCallback, useMemo } from 'react'

import { FC } from '@publica/ui-common-utils'
import {
    ControlledForm,
    FormRules,
    FormValuesWithRequired,
    useCommonRules,
    useConnectControlledForm,
} from '@publica/ui-web-utils'

import {
    DonationFullOwnershipLedgerTransactionCreateInput,
    LedgerAccountType,
    useCreateDonationFullOwnershipLedgerTransactionMutation,
} from '../../../../../../data'
import { LedgerAccountSearch } from '../../../../../components'
import { useDistinctLedgerAccountValidators } from '../../../../../lib'
import {
    DebitAssetTypeForm,
    DebitAssetTypeFormValues,
    TransactionContextForm,
    TransactionContextFormValues,
    useTransactionFormTranslations,
} from '../components'

type DonationFullOwnershipFormProps = {
    ledgerId: string
    form: ControlledForm<string | undefined, DonationFullOwnershipFormValues, DonationFullOwnershipFormRequiredValues>
    refetchQueries?: InternalRefetchQueriesInclude
}

type DonationFullOwnershipFormValues = {
    creditorAccount: { id: string } | undefined
    debitorAccount: { id: string } | undefined
} & TransactionContextFormValues &
    DebitAssetTypeFormValues

type DonationFullOwnershipFormRequiredValues = FormValuesWithRequired<
    DonationFullOwnershipFormValues,
    'assetType' | 'amount' | 'creditorAccount' | 'debitorAccount' | 'occurredAt'
>

export const DonationFullOwnershipForm: FC<DonationFullOwnershipFormProps> = ({ ledgerId, form, refetchQueries }) => {
    const [createDonationFullOwnershipLedgerTransaction, { loading }] =
        useCreateDonationFullOwnershipLedgerTransactionMutation()

    const submit = useCallback(
        async (values: DonationFullOwnershipFormRequiredValues): Promise<string | undefined> => {
            const { occurredAt, comment, amount, creditorAccount, debitorAccount, assetType } = values

            const transaction: DonationFullOwnershipLedgerTransactionCreateInput = {
                context: {
                    occurredAt,
                    comment,
                },
                amount,
                creditorAccountId: creditorAccount.id,
                debitorAccountId: debitorAccount.id,
                assetTypeId: assetType.assetTypeId,
            }

            const args = {
                variables: {
                    ledgerId,
                    transaction,
                },
                refetchQueries,
            } as const

            return createDonationFullOwnershipLedgerTransaction(args).then(
                ({ data }) => data?.createDonationFullOwnershipLedgerTransaction.id
            )
        },
        [createDonationFullOwnershipLedgerTransaction, ledgerId, refetchQueries]
    )

    useConnectControlledForm(form, submit, loading)

    return <DonationFullOwnershipTransactionForm ledgerId={ledgerId} form={form.form} />
}

type DonationFullOwnershipTransactionFormProps = {
    ledgerId: string
    form: FormInstance<DonationFullOwnershipFormValues>
}

const DonationFullOwnershipTransactionForm: FC<DonationFullOwnershipTransactionFormProps> = ({ ledgerId, form }) => {
    const rules = useCommonRules()
    const { t } = useTransactionFormTranslations()

    const [debitAccountValidator, creditAccountValidator] = useDistinctLedgerAccountValidators(
        'debitorAccount',
        'creditorAccount'
    )

    const validation = useMemo<FormRules<DonationFullOwnershipFormValues>>(
        () => ({
            debitorAccount: [...rules.required, debitAccountValidator],
            creditorAccount: [...rules.required, creditAccountValidator],
        }),
        [creditAccountValidator, debitAccountValidator, rules.required]
    )

    const debitorAccount = Form.useWatch('debitorAccount', form)

    return (
        <Form form={form} layout="vertical">
            <TransactionContextForm>
                <Form.Item
                    name="debitorAccount"
                    label={t('debitorAccount')}
                    rules={validation.debitorAccount}
                    hasFeedback
                >
                    <LedgerAccountSearch
                        ledgerAccountTypes={donationFullOwnershipLedgerAccountType}
                        ledgerId={ledgerId}
                    />
                </Form.Item>
                <Form.Item
                    name="creditorAccount"
                    label={t('creditorAccount')}
                    rules={validation.creditorAccount}
                    hasFeedback
                >
                    <LedgerAccountSearch
                        ledgerAccountTypes={donationFullOwnershipLedgerAccountType}
                        ledgerId={ledgerId}
                    />
                </Form.Item>
                <DebitAssetTypeForm
                    form={form as unknown as FormInstance<DebitAssetTypeFormValues>}
                    ledgerId={ledgerId}
                    debitorAccountId={debitorAccount?.id}
                    creditorAccountField="creditorAccount"
                    holdershipTypes={'FULL_OWNERSHIP'}
                />
            </TransactionContextForm>
        </Form>
    )
}

const donationFullOwnershipLedgerAccountType: LedgerAccountType[] = ['PEA_PME', 'PEA', 'STANDARD']
