import sortBy from 'lodash/sortBy'
import { useCallback, useMemo } from 'react'

import { ledgerHoldershipTypeLookup } from '@publica/lookups'
import { createUseTranslation, useCurrentLocale } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { FilterColumnType, FilterTable } from '@publica/ui-web-components'

import { LedgerAccountLabel, LedgerAmount } from '../../../../../components'
import { LedgerEntry, LedgerTransaction } from '../types'

type LedgerEntryTableProps = {
    transaction: LedgerTransaction
}

export const LedgerEntryTable: FC<LedgerEntryTableProps> = ({ transaction }) => {
    const { t } = useLedgerEntryTableTranslation()
    const locale = useCurrentLocale()

    const columns = useMemo<FilterColumnType<LedgerEntry>[]>(
        () => [
            {
                title: t('account'),
                render: (_, entry) => <LedgerAccountLabel account={entry.account} />,
            },
            {
                title: t('assetType'),
                render: (_, entry) => entry.assetType.name,
                width: 280,
            },
            {
                title: t('holdership'),
                render: (_, entry) => ledgerHoldershipTypeLookup.labelForKeyAndLocale(entry.holdershipType, locale),
                width: 280,
            },
            {
                title: t('amount'),
                render: (_, entry) => <LedgerAmount amount={entry.amount} />,
                width: 280,
            },
        ],
        [t, locale]
    )

    const sortedStatements = useMemo(() => sortBy(transaction.entries, entry => entry.sequence), [transaction.entries])
    const title = useCallback(() => t('entries'), [t])

    return <FilterTable<LedgerEntry> columns={columns} dataSource={sortedStatements} title={title} />
}

const useLedgerEntryTableTranslation = createUseTranslation({
    FR: {
        account: 'Compte',
        assetType: 'Actif',
        amount: 'Nombre',
        holdership: 'Détention',
        entries: 'Écritures',
    },
    EN: {
        account: 'Account',
        assetType: 'Asset',
        amount: 'Amount',
        holdership: 'Holdership',
        entries: 'Entries',
    },
})
