import { ColumnType } from 'antd/lib/table'
import { ExpandableConfig } from 'antd/lib/table/interface'
import { useMemo } from 'react'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { FilterColumnType, FilterTable, VerticalSpacer } from '@publica/ui-web-components'

import { LedgerAssetTypeTable } from '../LedgerAssetTypeTable'
import { useAssetTypeAmountsColumns, useCapitalAmountColumns } from '../common'
import { LedgerAccount, LedgerAssetType, LedgerBeneficiary } from '../types'

type LedgerBeneficiaryTableProps = {
    loading?: boolean
    ledgerBeneficiaries: LedgerBeneficiary[]
    ledgerAssetTypes: LedgerAssetType[]
}

export const LedgerBeneficiaryTable: FC<LedgerBeneficiaryTableProps> = ({
    loading = false,
    ledgerAssetTypes,
    ledgerBeneficiaries,
}) => {
    const { t } = useLedgerBeneficiaryTableTranslation()

    const capitalColumns = useCapitalAmountColumns<LedgerBeneficiary>()
    const assetTypeColumns = useAssetTypeAmountsColumns<LedgerBeneficiary>(ledgerAssetTypes)

    const columns = useMemo<(FilterColumnType<LedgerBeneficiary> | ColumnType<LedgerBeneficiary>)[]>(
        () => [
            {
                title: t('legalEntity'),
                render: (_, { legalEntity }) => legalEntity.name,
                filterValue: ({ legalEntity }) => legalEntity.name,
            },
            ...capitalColumns,
            ...assetTypeColumns,
        ],
        [assetTypeColumns, capitalColumns, t]
    )

    const expandable: ExpandableConfig<LedgerBeneficiary> = useMemo(
        () => ({
            expandedRowRender: ledgerBeneficiary => (
                <LedgerAccountsForLedgerBeneficiary
                    ledgerAccounts={ledgerBeneficiary.accounts}
                    ledgerAssetTypes={ledgerAssetTypes}
                />
            ),
        }),
        [ledgerAssetTypes]
    )

    return (
        <FilterTable<LedgerBeneficiary>
            expandable={expandable}
            dataSource={ledgerBeneficiaries}
            loading={loading}
            columns={columns}
            scrollsHorizontally
        />
    )
}

const useLedgerBeneficiaryTableTranslation = createUseTranslation({
    FR: {
        accounts: 'Comptes',
        legalEntity: `Entité légale`,
        holding: 'Détention',
        holdingPercentage: 'Détention (%)',
    },
    EN: {
        accounts: 'Accounts',
        legalEntity: 'Legal Entity',
        holding: 'Holdership',
        holdingPercentage: 'Holdership (%)',
    },
})

type LedgerAccountsForLedgerBeneficiaryProps = {
    loading?: boolean
    ledgerAccounts: LedgerAccount[]
    ledgerAssetTypes: LedgerAssetType[]
}

const LedgerAccountsForLedgerBeneficiary: FC<LedgerAccountsForLedgerBeneficiaryProps> = ({
    ledgerAccounts,
    ledgerAssetTypes,
    loading = false,
}) => {
    return (
        <VerticalSpacer size={10}>
            {ledgerAccounts.map((ledgerAccount, idx) => (
                <LedgerAssetTypeTable
                    key={idx}
                    ledgerAccount={ledgerAccount}
                    ledgerAssetTypes={ledgerAssetTypes}
                    loading={loading}
                />
            ))}
            <br />
        </VerticalSpacer>
    )
}
