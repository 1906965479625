import { EuroOutlined, SwapOutlined, UserOutlined } from '@ant-design/icons'
import { Card, Tabs } from 'antd'
import { useCallback, useEffect, useMemo } from 'react'
import { useMatch, useNavigate, useParams, useResolvedPath } from 'react-router-dom'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { Pending } from '@publica/ui-web-components'
import { useTabs } from '@publica/ui-web-utils'
import { assert } from '@publica/utils'

import { useGetLedgerQuery } from '../../../../data'
import { LedgerHeader } from '../../../components'
import { LedgerAccounts } from '../../ledgerAccounts'
import { LedgerAssetTypes } from '../../ledgerAssetTypes'
import { LedgerTransactions } from '../../ledgerTransactions'

type Ledger = {
    id: string
    name: string
}

export const ShowLedger: FC = () => {
    useRedirectFromRootToTransactions()

    const { ledgerId } = useParams()
    assert.defined(ledgerId, 'Undefined ledger ID')

    const { t } = useShowLedgerTranslation()
    const { data, loading } = useGetLedgerQuery({ variables: { ledgerId }, pollInterval: __SLOW_POLLING__ })

    const render = useCallback((ledger: Ledger) => <ShowLedgerInternal ledger={ledger} />, [])

    return <Pending loading={loading} record={data?.ledger} notFoundText={t('ledgerNotFound')} render={render} />
}

const useShowLedgerTranslation = createUseTranslation({
    FR: {
        ledgerNotFound: 'Registre non trouvée',
        transactions: 'Transactions',
        accounts: 'Comptes',
        assetTypes: 'Actifs',
    },
    EN: {
        ledgerNotFound: 'Ledger not found',
        transactions: 'Transactions',
        accounts: 'Accounts',
        assetTypes: 'Asset types',
    },
})

const useRedirectFromRootToTransactions = () => {
    const rootResolved = useResolvedPath('')
    const isRoot = useMatch({ path: rootResolved.pathname, end: true }) !== null

    const navigate = useNavigate()

    useEffect(() => {
        if (isRoot) {
            navigate('transactions')
        }
    }, [isRoot, navigate])
}

type ShowLedgerInternalProps = {
    ledger: Ledger
}

const ShowLedgerInternal: FC<ShowLedgerInternalProps> = ({ ledger }) => {
    const { t } = useShowLedgerTranslation()

    const tabs = useTabs(
        useMemo(
            () => [
                {
                    key: 'transactions',
                    label: t('transactions'),
                    content: <LedgerTransactions ledger={ledger} />,
                    icon: <SwapOutlined />,
                },
                {
                    key: 'accounts',
                    label: t('accounts'),
                    content: <LedgerAccounts ledger={ledger} />,
                    icon: <UserOutlined />,
                },
                {
                    key: 'assetTypes',
                    label: t('assetTypes'),
                    content: <LedgerAssetTypes ledger={ledger} />,
                    icon: <EuroOutlined />,
                },
            ],
            [ledger, t]
        )
    )

    const title = useMemo(() => <LedgerHeader ledger={ledger} />, [ledger])

    return (
        <Card title={title}>
            <Tabs
                defaultActiveKey="transactions"
                activeKey={tabs.activeTab}
                onTabClick={tabs.onChange}
                items={tabs.items}
            />
        </Card>
    )
}
