import { InputNumber, InputNumberProps } from 'antd'
import { CSSProperties } from 'react'

type AmountInputProps = InputNumberProps

export const AmountInput = ({ min = 0, ...props }: AmountInputProps) => (
    <InputNumber style={styles} min={min} {...props} />
)

const styles: CSSProperties = {
    width: '100%',
}
