import { TabsProps } from 'antd'
import { ReactNode, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

// This assumes that the last segment is the tab key
export const useActiveTab = <T extends string = string>(validTabs?: T[]): T => {
    const location = useLocation()
    // We can cast as the array is at least 1 element long
    const tab = location.pathname.split('/').pop() as string

    if (validTabs === undefined || validTabs.includes(tab as T)) {
        return tab as T
    }

    throw new Error(`Unknown tab: ${tab}`)
}

type _Tab = NonNullable<TabsProps['items']>[number]

type Tab = {
    key: string
    label: string
    content: ReactNode
    icon?: ReactNode
}

type TabConfig = {
    items: _Tab[]
    activeTab: string
    onChange: (key: string) => void
}

export const useTabs = <T extends Tab[]>(items: T): TabConfig => {
    const validKeys = useMemo(() => items.map(({ key }) => key), [items])
    const activeTab = useActiveTab(validKeys)
    const navigate = useNavigate()

    const onChange = useCallback(
        (key: string) => {
            if (validKeys.includes(key)) {
                navigate(key)
            }
        },
        [navigate, validKeys]
    )

    const tabItems = useMemo(
        () =>
            items.map(({ key, content, ...label }) => ({
                key,
                children: content,
                label: <span>{label.label}</span>,
            })),
        [items]
    )

    return {
        items: tabItems,
        activeTab,
        onChange,
    }
}
