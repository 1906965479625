import { Rule } from 'antd/lib/form'
import isNil from 'lodash/isNil'
import { useMemo } from 'react'

import { renderValue } from '@publica/render'
import { createUseTranslation, useCurrentLocale } from '@publica/ui-common-i18n'

import { LedgerAccountType } from '../data'

const defaultOptions = {
    length: 4,
    skipHash: false,
}

type FormatTransactionSequenceOptions = {
    length?: number
    skipHash?: boolean
}

export const formatTransactionSequence = (
    seq: number | null | undefined,
    options?: FormatTransactionSequenceOptions
): string | undefined => {
    const length = options?.length ?? defaultOptions.length
    const skipHash = options?.skipHash ?? defaultOptions.skipHash

    let repr: string

    if (isNil(seq)) {
        return undefined
    } else {
        const seqStr = seq.toString()
        repr = `${'0'.repeat(length - seqStr.length)}${seqStr}`
    }

    return skipHash ? repr : `#${repr}`
}

export const useDistinctLedgerAccountValidators = (aFieldKey: string, bFieldKey: string) => {
    const { t } = useDistinctLedgerAccountValidatorTranslation()

    return useMemo<[Rule, Rule]>(
        () => [
            // NOTE: the field keys are _supposed_ to be inverted
            createDistinctObjectValidator(bFieldKey, t('accountsMustDiffer')),
            createDistinctObjectValidator(aFieldKey, t('accountsMustDiffer')),
        ],
        [aFieldKey, bFieldKey, t]
    )
}

const useDistinctLedgerAccountValidatorTranslation = createUseTranslation({
    FR: {
        accountsMustDiffer: 'Les comptes doivent être différents',
    },
    EN: {
        accountsMustDiffer: 'Accounts must be different',
    },
})

export const useDistinctLedgerAssetTypeValidators = (
    aFieldKey: string,
    bFieldKey: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    opts?: { aTransform?: (a: any) => { id: string } | undefined; bTransform?: (b: any) => { id: string } | undefined }
) => {
    const { t } = useDistinctLedgerAssetTypeValidatorTranslation()

    return useMemo<[Rule, Rule]>(
        () => [
            // NOTE: the field keys are _supposed_ to be inverted
            createDistinctObjectValidator(bFieldKey, t('assetTypesMustDiffer'), {
                transform: opts?.aTransform,
                otherTransform: opts?.bTransform,
            }),
            createDistinctObjectValidator(aFieldKey, t('assetTypesMustDiffer'), {
                transform: opts?.bTransform,
                otherTransform: opts?.aTransform,
            }),
        ],
        [aFieldKey, bFieldKey, opts?.aTransform, opts?.bTransform, t]
    )
}

const useDistinctLedgerAssetTypeValidatorTranslation = createUseTranslation({
    FR: {
        assetTypesMustDiffer: 'Les actifs doivent être différents',
    },
    EN: {
        assetTypesMustDiffer: 'Asset types must be different',
    },
})

const createDistinctObjectValidator =
    (
        key: string,
        errorMessage: string,
        opts?: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            transform?: (obj: any) => { id: string } | undefined
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            otherTransform?: (obj: any) => { id: string } | undefined
        }
    ): Rule =>
    ({ getFieldValue }) => ({
        validator: async (_, thisObj: { id: string } | undefined) => {
            const value = getFieldValue(key) as unknown

            const otherFinalObj =
                opts?.otherTransform === undefined ? (value as { id: string } | undefined) : opts?.otherTransform(value)

            const thisFinalObj = opts?.transform === undefined ? thisObj : opts?.transform(thisObj)

            return new Promise<void>((resolve, reject) => {
                if (thisFinalObj?.id === otherFinalObj?.id) {
                    reject(new Error(errorMessage))
                } else {
                    resolve()
                }
            })
        },
    })

type LedgerAccount = { id: string; type: LedgerAccountType }
type LedgerAssetType = { id: string; eligibleAccountTypes: LedgerAccountType[] }

export const useLedgerAssetTypeEligibilityValidator = (accountField: string) => {
    const { t } = useLedgerAssetTypeEligibilityValidatorTranslation()

    return useMemo<Rule>(
        () =>
            ({ getFieldValue }) => ({
                validator: async (_, assetType: LedgerAssetType | undefined) => {
                    const account = getFieldValue(accountField) as LedgerAccount | undefined

                    if (
                        assetType !== undefined &&
                        account !== undefined &&
                        !assetType.eligibleAccountTypes.includes(account.type)
                    ) {
                        throw new Error(t('assetNotEligible'))
                    }
                },
            }),
        [accountField, t]
    )
}

const useLedgerAssetTypeEligibilityValidatorTranslation = createUseTranslation({
    FR: {
        assetNotEligible: `Cet actif n'est pas éligible pour ce type de compte`,
    },
    EN: {
        assetNotEligible: 'This asset type is not elible for this account type',
    },
})

export const useRenderedAmount = (amount: number) => {
    const locale = useCurrentLocale()

    return useMemo(
        () =>
            renderValue({
                type: 'FloatValue',
                floatValue: amount,
                options: {
                    locale,
                    numberFormat: 'NUMBER',
                },
            }),
        [amount, locale]
    )
}

export const useRenderedPercentage = (amount: number) => {
    const rounded = Math.round((amount + Number.EPSILON) * 100) / 100
    const rendered = useRenderedAmount(rounded)
    return `${rendered}%`
}
