import { ColumnType } from 'antd/lib/table'
import { ExpandableConfig } from 'antd/lib/table/interface'
// eslint-disable-next-line you-dont-need-lodash-underscore/reduce
import reduce from 'lodash/reduce'
import sortBy from 'lodash/sortBy'
import { useCallback, useMemo } from 'react'

import { ledgerAccountTypeLookup, ledgerHoldershipTypeLookup } from '@publica/lookups'
import { createUseTranslation, useCurrentLocale } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { FilterTable } from '@publica/ui-web-components'
import { buildMapWithFn } from '@publica/utils'

import { LedgerBalanceAmount } from '../LedgerBalanceAmount'
import { LedgerBalanceOriginTable } from '../LedgerBalanceOriginTable'
import {
    LedgerAccount,
    LedgerAssetType,
    LedgerBalanceOrigin,
    LedgerBalanceAmount as TLedgerBalanceAmount,
} from '../types'

type LedgerAssetTypeTableProps = {
    loading?: boolean
    ledgerAccount: LedgerAccount
    ledgerAssetTypes: LedgerAssetType[]
}

export const LedgerAssetTypeTable: FC<LedgerAssetTypeTableProps> = ({
    loading = false,
    ledgerAccount,
    ledgerAssetTypes,
}) => {
    const locale = useCurrentLocale()
    const { t } = useLedgerAccountTableTranslation()

    const title = useCallback(
        () => ledgerAccountTypeLookup.labelForKeyAndLocale(ledgerAccount.type, locale),
        [ledgerAccount.type, locale]
    )

    const balancesByAssetTypeId = buildMapWithFn(
        assetTypeBalance => assetTypeBalance.assetType.id,
        ledgerAccount.balance.balanceByAssetType
    )

    const columns = useMemo<ColumnType<LedgerAssetType>[]>(
        () => [
            {
                title: t('type'),
                render: (_, ledgerAssetType) => ledgerAssetType.name,
            },
            ...ledgerHoldershipTypeLookup.entriesForLocale(locale).map(
                (type): ColumnType<LedgerAssetType> => ({
                    title: type.value,
                    render: (_, { id }) => {
                        const balance = balancesByAssetTypeId[id]
                        let amount: TLedgerBalanceAmount | undefined

                        switch (type.key) {
                            case 'FULL_OWNERSHIP':
                                amount = balance?.balanceByHoldership.fullOwnership.amount
                                break
                            case 'BARE_OWNERSHIP':
                                amount = balance?.balanceByHoldership.bareOwnership.amount
                                break
                            case 'USUFRUCT':
                                amount = balance?.balanceByHoldership.usufruct.amount
                        }

                        return <LedgerBalanceAmount amount={amount} />
                    },
                    align: 'right',
                })
            ),
        ],
        [balancesByAssetTypeId, locale, t]
    )

    const sortedLedgerAssetTypes = useMemo(
        () => sortBy(ledgerAssetTypes, ledgerAssetType => ledgerAssetType.name),
        [ledgerAssetTypes]
    )

    const originsByAssetTypeId = useMemo(
        () =>
            reduce(
                balancesByAssetTypeId,
                (origins, assetTypeBalance) => {
                    const mergedOrigins = [
                        ...(assetTypeBalance.balanceByHoldership?.fullOwnership.origins ?? []),
                        ...(assetTypeBalance.balanceByHoldership?.usufruct.origins ?? []),
                        ...(assetTypeBalance.balanceByHoldership?.bareOwnership.origins ?? []),
                    ]

                    return {
                        ...origins,
                        [assetTypeBalance.assetType.id]: mergedOrigins,
                    }
                },
                {} as Record<string, LedgerBalanceOrigin[]>
            ),
        [balancesByAssetTypeId]
    )

    const expandable = useMemo<ExpandableConfig<LedgerAssetType>>(
        () => ({
            rowExpandable: ({ id }) => (originsByAssetTypeId[id]?.length ?? 0) > 0,
            expandedRowRender: ({ id }) => {
                const origins = originsByAssetTypeId[id] ?? []
                return <LedgerBalanceOriginTable origins={origins} />
            },
        }),
        [originsByAssetTypeId]
    )

    return (
        <FilterTable<LedgerAssetType>
            title={title}
            dataSource={sortedLedgerAssetTypes}
            loading={loading}
            columns={columns}
            expandable={expandable}
        />
    )
}

const useLedgerAccountTableTranslation = createUseTranslation({
    FR: {
        type: `Actif`,
    },
    EN: {
        type: 'Asset type',
    },
})
