import { InternalRefetchQueriesInclude } from '@apollo/client'
import { Form, Input } from 'antd'
import isNil from 'lodash/isNil'
import { useCallback, useMemo } from 'react'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import {
    ControlledForm,
    ControlledFormWithElement,
    FormRules,
    asyncValidator,
    useCommonRules,
    useControlledForm,
} from '@publica/ui-web-utils'
import { assert } from '@publica/utils'

import { useCheckLedgerNameLazyQuery, useCreateLedgerMutation } from '../../../../data'

type NewLedgerFormProps = {
    form: ControlledForm<string | undefined, NewLedgerFormValues>
}

const NewLedgerForm: FC<NewLedgerFormProps> = ({ form }) => {
    const { t } = useNewLedgerFormTranslation()
    const [checkLedgerNameQuery] = useCheckLedgerNameLazyQuery()

    const rules = useCommonRules()

    const validationRules = useMemo<FormRules<NewLedgerFormValues>>(
        () => ({
            name: [
                ...rules.required,
                asyncValidator(async (name: NewLedgerFormValues['name']) => {
                    if (name === undefined) {
                        return
                    }

                    const { data } = await checkLedgerNameQuery({
                        variables: {
                            name,
                        },
                    })

                    if (!isNil(data?.ledgerByName?.id)) {
                        throw new Error(t('alreadyLedgerWithName', { name }))
                    }
                }),
            ],
        }),
        [checkLedgerNameQuery, rules.required, t]
    )

    return (
        <Form form={form.form} layout="vertical">
            <Form.Item name="name" label={t('name')} hasFeedback validateFirst={true} rules={validationRules.name}>
                <Input />
            </Form.Item>
        </Form>
    )
}

const useNewLedgerFormTranslation = createUseTranslation({
    FR: {
        alreadyLedgerWithName: `Il existe déjà un registre nommé "{{name}}"`,
        name: 'Nom',
    },
    EN: {
        alreadyLedgerWithName: `There is already a a ledger named "{{name}}"`,
        name: 'Name',
    },
})

type NewLedgerFormValues = {
    name: string | undefined
}

type NewLedgerFormOptions = {
    refetchQueries?: InternalRefetchQueriesInclude
}

export const useNewLedgerForm = (
    options?: NewLedgerFormOptions
): ControlledFormWithElement<string | undefined, NewLedgerFormValues> => {
    const [createLedgerMutation, { loading }] = useCreateLedgerMutation()

    const submit = useCallback(
        async (values: NewLedgerFormValues) => {
            const { name } = values

            assert.defined(name)

            return createLedgerMutation({
                variables: {
                    name,
                },
                refetchQueries: options?.refetchQueries,
            }).then(({ data }) => data?.createLedger.id)
        },
        [createLedgerMutation, options?.refetchQueries]
    )

    return useControlledForm({
        render: form => <NewLedgerForm form={form} />,
        submit,
        loading,
    })
}
