import { ColumnType } from 'antd/lib/table'
import orderBy from 'lodash/orderBy'
import { useMemo } from 'react'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { FilterTable } from '@publica/ui-web-components'

import { LedgerBalanceOriginLabel } from '../../../../components'
import { LedgerBalanceAmount } from '../LedgerBalanceAmount'
import { LedgerBalanceOrigin } from '../types'

type LedgerBalanceOriginTableProps = {
    origins: LedgerBalanceOrigin[]
}

export const LedgerBalanceOriginTable: FC<LedgerBalanceOriginTableProps> = ({ origins }) => {
    const { t } = useLedgerBalanceOriginTableTranslation()

    const sortedOrigins = useMemo(
        () => orderBy(origins, ['historical', 'status', 'holdershipType'], ['asc', 'desc', 'asc']),
        [origins]
    )

    const columns = useMemo<ColumnType<LedgerBalanceOrigin>[]>(
        () => [
            {
                title: t('origin'),
                render: (_, origin) => {
                    // This is a workaround for some type issues
                    switch (origin.__typename) {
                        case 'LedgerFullOwnershipBalanceOrigin':
                            // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
                            return <LedgerBalanceOriginLabel origin={{ ...origin, holdershipType: 'FULL_OWNERSHIP' }} />
                        case 'LedgerDismemberedBalanceOrigin':
                            return (
                                <LedgerBalanceOriginLabel
                                    // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
                                    origin={{
                                        ...origin,
                                        holdershipType: origin.holdershipType as 'BARE_OWNERSHIP' | 'USUFRUCT',
                                    }}
                                />
                            )
                    }
                },
                width: 700,
            },
            {
                title: t('amount'),
                render: (_, origin) => <LedgerBalanceAmount amount={origin.amount} />,
                align: 'right',
            },
        ],
        [t]
    )

    return <FilterTable<LedgerBalanceOrigin> columns={columns} dataSource={sortedOrigins} />
}

// // type NormalizedLedgerBalance = {
// //     id: string
// //     amount: number
// //     historical: boolean
// //     status: LedgerTransactionStatus
// // } & (
// //     | { holdershipType: 'FULL_OWNERSHIP' }
// //     | {
// //           holdershipType: 'BARE_OWNERSHIP' | 'USUFRUCT'
// //           dismemberment: LedgerDismemberment
// //       }
// // )

// // const useNormalizedLedgerBalances = (balances: LedgerBalance[]): NormalizedLedgerBalance[] =>
// //     useMemo(
// //         () =>
// //             map(balances, (balance): NormalizedLedgerBalance => {
// //                 let historical: boolean
// //                 let status: LedgerTransactionStatus

// //                 if (
// //                     balance.__typename === 'LedgerHistoricalDismemberedBalance' ||
// //                     balance.__typename === 'LedgerHistoricalFullOwnershipBalance'
// //                 ) {
// //                     historical = true
// //                     status = 'COMMITTED'
// //                 } else {
// //                     historical = false
// //                     status = balance.status
// //                 }

// //                 if (
// //                     balance.__typename === 'LedgerCurrentFullOwnershipBalance' ||
// //                     balance.__typename === 'LedgerHistoricalFullOwnershipBalance'
// //                 ) {
// //                     return {
// //                         id: balance.id,
// //                         amount: balance.amount,
// //                         historical,
// //                         status,
// //                         holdershipType: 'FULL_OWNERSHIP',
// //                     }
// //                 } else {
// //                     return {
// //                         id: balance.id,
// //                         amount: balance.amount,
// //                         historical,
// //                         status,
// //                         holdershipType: balance.type,
// //                         dismemberment: balance.dismemberment,
// //                     }
// //                 }
// //             }),
// //         [balances]
// //     )

const useLedgerBalanceOriginTableTranslation = createUseTranslation({
    FR: {
        origin: 'Origine',
        amount: 'Nombre',
    },
    EN: {
        origin: 'Origin',
        amount: 'Amount',
    },
})
