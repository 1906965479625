import { PlusOutlined } from '@ant-design/icons'
import { Col, Divider, Form, Input, Row, Select } from 'antd'
import isArray from 'lodash/isArray'
import reject from 'lodash/reject'
import sortBy from 'lodash/sortBy'
import { ReactElement, useCallback, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC, useAsyncCallback } from '@publica/ui-common-utils'
import { ActionButton } from '@publica/ui-web-components'
import { assert, buildIdMap } from '@publica/utils'

import { LedgerAccountType, useCreateLedgerAssetTypeMutation, useGetLedgerAssetTypesQuery } from '../../../data'

type LedgerAssetTypeSelectProps = {
    ledgerId: string
    onChange?: (value: LedgerAssetType | undefined) => void
    value?: LedgerAssetType
    allowNewAssetTypes?: boolean
}

type LedgerAssetType = {
    id: string
    name: string
    eligibleAccountTypes: LedgerAccountType[]
}

const useLedgerAssetTypeSelectStyles = createUseStyles({
    selectWrapper: {
        padding: [0, 10],
    },
    divider: {
        margin: [8, 0],
        marginBottom: 10,
    },
})

export const LedgerAssetTypeSelect: FC<LedgerAssetTypeSelectProps> = ({
    ledgerId,
    onChange,
    allowNewAssetTypes = true,
}) => {
    const styles = useLedgerAssetTypeSelectStyles()
    const [newAssetTypes, setNewAssetTypes] = useState<LedgerAssetType[]>([])

    const { data, loading } = useGetLedgerAssetTypesQuery({
        variables: {
            ledgerId,
        },
        pollInterval: __SLOW_POLLING__,
    })

    const combinedRemoteAndLocal = useMemo(() => {
        const existingAssetTypes = data?.ledger?.assetTypes ?? []
        const existingAssetTypesMap = buildIdMap(existingAssetTypes)

        return [
            ...existingAssetTypes,
            ...reject(newAssetTypes, assetType => existingAssetTypesMap[assetType.id] !== undefined),
        ]
    }, [data?.ledger?.assetTypes, newAssetTypes])

    const ledgerAssetTypeById = useMemo(() => buildIdMap(combinedRemoteAndLocal), [combinedRemoteAndLocal])

    const options = useMemo(
        () =>
            sortBy(combinedRemoteAndLocal, assetType => assetType.name).map(assetType => ({
                label: assetType.name,
                value: assetType.id,
            })),
        [combinedRemoteAndLocal]
    )

    const onAddAssetType = useCallback((assetType: LedgerAssetType) => {
        setNewAssetTypes(val => [...val, assetType])
    }, [])

    const render = useCallback(
        (menu: ReactElement): ReactElement => (
            <>
                {menu}
                {allowNewAssetTypes ? (
                    <>
                        <Divider className={styles.divider} />
                        <div className={styles.selectWrapper}>
                            <NewLedgerAssetTypeForm
                                ledgerId={ledgerId}
                                onAddAssetType={onAddAssetType}
                                existingAssetTypes={combinedRemoteAndLocal}
                            />
                        </div>
                    </>
                ) : null}
            </>
        ),
        [allowNewAssetTypes, combinedRemoteAndLocal, ledgerId, onAddAssetType, styles.divider, styles.selectWrapper]
    )

    const onSelectAssetType = useCallback(
        (_: OptionType, option: OptionType | OptionType[]) => {
            if (!isArray(option)) {
                const ledgerAssetType = ledgerAssetTypeById[option.value]
                onChange?.(ledgerAssetType)
            }
        },
        [ledgerAssetTypeById, onChange]
    )

    return <Select loading={loading} dropdownRender={render} options={options} onChange={onSelectAssetType} />
}

type OptionType = {
    label: string
    value: string
}

type NewLedgerAssetTypeFormProps = {
    ledgerId: string
    onAddAssetType: (assetType: LedgerAssetType) => void
    existingAssetTypes: LedgerAssetType[]
}

type NewLedgerAssetTypeFormValues = {
    name: string | undefined
}

const useNewLedgerAssetTypeFormStyles = createUseStyles({
    buttonWrapper: {
        textAlign: 'right',
    },
    button: {
        marginLeft: 10,
    },
})

const useNewLedgerAssetTypeFormTranslations = createUseTranslation({
    FR: {
        add: 'Ajouter',
        name: `Type d'actif`,
    },
    EN: {
        add: 'Add',
        name: 'Asset type',
    },
})

const NewLedgerAssetTypeForm: FC<NewLedgerAssetTypeFormProps> = ({ ledgerId, onAddAssetType, existingAssetTypes }) => {
    const styles = useNewLedgerAssetTypeFormStyles()
    const { t } = useNewLedgerAssetTypeFormTranslations()
    const [form] = Form.useForm<NewLedgerAssetTypeFormValues>()

    const [createLedgerAssetType] = useCreateLedgerAssetTypeMutation()

    const name = Form.useWatch('name', form)
    const existingAssetTypeNames = useMemo(
        () => new Set(existingAssetTypes.map(assetType => assetType.name.trim().toLowerCase())),
        [existingAssetTypes]
    )
    const createDisabled = useMemo(() => {
        if (name === undefined) {
            return true
        }

        const trimmed = name.trim()

        if (trimmed.length === 0) {
            return true
        }

        return existingAssetTypeNames.has(trimmed)
    }, [existingAssetTypeNames, name])

    const submit = useAsyncCallback(async () => {
        const { name } = form.getFieldsValue()

        assert.defined(name)

        await createLedgerAssetType({
            variables: {
                ledgerId,
                name,
            },
        }).then(result => {
            form.resetFields()
            if (result.data?.createLedgerAssetType !== undefined) {
                onAddAssetType(result.data?.createLedgerAssetType)
            }
        })
    }, [createLedgerAssetType, form, ledgerId, onAddAssetType])

    return (
        <Row justify="space-evenly">
            <Col flex="auto">
                <Form form={form}>
                    <Form.Item name="name">
                        <Input placeholder={t('name')} />
                    </Form.Item>
                </Form>
            </Col>
            <Col className={styles.buttonWrapper}>
                <ActionButton
                    size="middle"
                    icon={plus}
                    className={styles.button}
                    onClick={submit}
                    disabled={createDisabled}
                >
                    {t('add')}
                </ActionButton>
            </Col>
        </Row>
    )
}

const plus = <PlusOutlined />
