import { FC } from '@publica/ui-common-utils'

import { LedgerAmount, UncommittedValue } from '../../../../components'
import { LedgerBalanceAmount as TLedgerBalanceAmount } from '../types'

type LedgerBalanceAmountProps = {
    amount?: TLedgerBalanceAmount
    mode?: 'amount' | 'percentage'
}

const nullAmount: TLedgerBalanceAmount = {
    committedAmount: 0,
    uncommittedAmount: 0,
}

export const LedgerBalanceAmount: FC<LedgerBalanceAmountProps> = ({ amount = nullAmount, mode }) => {
    if (amount.committedAmount === amount.uncommittedAmount) {
        return <LedgerAmount amount={amount.committedAmount} mode={mode} />
    }
    return (
        <>
            <LedgerAmount amount={amount.committedAmount} mode={mode} />{' '}
            <UncommittedValue>({<LedgerAmount amount={amount.uncommittedAmount} mode={mode} />})</UncommittedValue>
        </>
    )
}
