import { InternalRefetchQueriesInclude } from '@apollo/client'
import { useCallback } from 'react'

import { FC } from '@publica/ui-common-utils'
import { ControlledForm, FormValuesWithRequired, useConnectControlledForm } from '@publica/ui-web-utils'

import { LedgerAccountType, useCreateCancellationLedgerTransactionMutation } from '../../../../../../data'
import { DebitOnlyTransactionForm, DebitOnlyTransactionFormValues } from '../components'

type CancellationFormProps = {
    ledgerId: string
    form: ControlledForm<string | undefined, CancellationFormValues, CancellationFormWithRequiredValues>
    refetchQueries?: InternalRefetchQueriesInclude
}

type CancellationFormValues = DebitOnlyTransactionFormValues
type CancellationFormWithRequiredValues = FormValuesWithRequired<
    CancellationFormValues,
    'debitorAccount' | 'occurredAt' | 'amount' | 'assetType'
>

export const CancellationForm: FC<CancellationFormProps> = ({ ledgerId, form, refetchQueries }) => {
    const [createCancellationLedgerTransaction, { loading }] = useCreateCancellationLedgerTransactionMutation()

    const submit = useCallback(
        async (values: CancellationFormWithRequiredValues): Promise<string | undefined> => {
            const { occurredAt, comment, amount, debitorAccount, assetType } = values

            const transaction = {
                context: {
                    occurredAt,
                    comment,
                },
                amount,
                debitorAccountId: debitorAccount.id,
                assetTypeId: assetType.assetTypeId,
            }

            return createCancellationLedgerTransaction({
                variables: {
                    ledgerId,
                    transaction,
                },
                refetchQueries,
            }).then(({ data }) => data?.createCancellationLedgerTransaction.id)
        },
        [createCancellationLedgerTransaction, ledgerId, refetchQueries]
    )

    useConnectControlledForm(form, submit, loading)

    return (
        <DebitOnlyTransactionForm
            ledgerAccountTypes={cancellationLedgerAccountTypes}
            ledgerId={ledgerId}
            form={form.form}
            holdershipTypes={'FULL_OWNERSHIP'}
        />
    )
}

const cancellationLedgerAccountTypes: LedgerAccountType[] = ['STANDARD', 'PEA', 'PEA_PME']
