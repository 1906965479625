import Form, { FormInstance, Rule } from 'antd/lib/form'
import debounce from 'lodash/debounce'
import { ReactElement, useEffect, useMemo } from 'react'

import { EventEmitter } from '@publica/utils'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FormValues<K extends string = string> = Record<K, any>

type FormSubmitResult<Result> = { validated: false } | { validated: true; result: Result }

type FormSubmitEvent<Result> = { type: 'submit'; result: FormSubmitResult<Result> }

export class ControlledFormChannel<
    Result,
    Values extends FormValues = FormValues,
    ValidatedValues extends Values = Values,
> {
    private isLoading = false
    private submitHandler: ((values: ValidatedValues) => Promise<Result>) | undefined
    public events: EventEmitter<FormSubmitEvent<Result>>

    constructor(private form: FormInstance<Values>) {
        this.events = new EventEmitter()
    }

    reset() {
        this.form.resetFields()
    }

    setLoading(loading: boolean) {
        this.isLoading = loading
    }

    get loading() {
        return this.isLoading
    }

    async submit(): Promise<FormSubmitResult<Result>> {
        const result = await this.submitWithoutEvents()
        await this.events.emit({
            type: 'submit',
            result,
        })
        return result
    }

    private async submitWithoutEvents(): Promise<FormSubmitResult<Result>> {
        try {
            await this.form.validateFields()
        } catch (e) {
            return { validated: false }
        }

        if (this.submitHandler === undefined) {
            throw new Error('No submit handler specified')
        }

        const result = await this.submitHandler(this.form.getFieldsValue() as ValidatedValues)

        return {
            validated: true,
            result,
        }
    }

    clearSubmitHandler() {
        this.submitHandler = undefined
    }

    setSubmitHandler(handler: (values: ValidatedValues) => Promise<Result>) {
        this.submitHandler = handler
    }
}

export type ControlledForm<Result, Values extends FormValues = FormValues, ValidatedValues extends Values = Values> = {
    form: FormInstance<Values>
    control: ControlledFormChannel<Result, Values, ValidatedValues>
}

export type ControlledFormWithElement<Result, Values extends FormValues = FormValues> = ControlledForm<
    Result,
    Values
> & {
    element: ReactElement
}

type ControlledFormOptions<Result, Values extends FormValues> = {
    render: (form: Omit<ControlledForm<Result, Values>, 'element'>) => ReactElement
    loading?: boolean
    submit?: (values: Values) => Promise<Result>
}

export const useControlledForm = <Result, Values extends FormValues>(
    options: ControlledFormOptions<Result, Values>
): ControlledFormWithElement<Result, Values> => {
    const [form] = Form.useForm<Values>()

    const control = useMemo(() => new ControlledFormChannel<Result, Values>(form), [form])
    const element = useMemo(() => options.render({ control, form }), [control, form, options])

    useConnectControlledForm({ control, form }, options.submit, options.loading)

    return { control, form, element }
}

export const useConnectControlledForm = <
    Result,
    Values extends FormValues = FormValues,
    ValidatedValues extends Values = Values,
>(
    form: ControlledForm<Result, Values, ValidatedValues>,
    submit?: (values: ValidatedValues) => Promise<Result>,
    loading?: boolean
) => {
    useEffect(() => {
        if (loading === undefined) {
            return
        }

        form.control.setLoading(loading)
    }, [form, loading])

    useEffect(() => {
        if (submit === undefined) {
            return
        }

        form.control.setSubmitHandler(submit)
        return () => {
            form.control.clearSubmitHandler()
        }
    }, [form, submit])
}

type Validator<V> = (val: V) => Promise<void>

export const asyncValidator = <V>(validator: Validator<V>) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const debounced = debounce((value: any, callback: (error?: string) => void): void => {
        validator(value)
            .then(() => callback())
            .catch(err => callback(err))
    }, 500)

    const rule: Rule = {
        validator: async (_, value) =>
            new Promise<void>((resolve, reject) => {
                debounced(value, error => {
                    if (error !== undefined) {
                        reject(error)
                    } else {
                        resolve()
                    }
                })
            }),
    }

    return rule
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FormValuesWithRequired<V extends Record<string, any>, R extends keyof V> = Omit<V, R> & {
    [K in R]-?: NonNullable<V[K]>
}
