import isArray from 'lodash/isArray'
import { useCallback } from 'react'

import { useCurrentLocale } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { RemoteSelect } from '@publica/ui-web-components'

import { ledgerAccountLabel } from '../'
import { LedgerAccountType, useSearchLedgerAccountsLazyQuery } from '../../../data'

type LedgerAccount = {
    id: string
    type: LedgerAccountType
}

type LedgerAccountSearchProps = {
    onChange?: (value: LedgerAccount | undefined) => void
    value?: LedgerAccount
    ledgerId: string
    ledgerAccountTypes?: LedgerAccountType[]
}

export const LedgerAccountSearch: FC<LedgerAccountSearchProps> = ({ ledgerId, onChange, ledgerAccountTypes }) => {
    const [searchLedgerAccounts, { loading }] = useSearchLedgerAccountsLazyQuery()
    const locale = useCurrentLocale()

    const search = useCallback<LedgerAccountSearch>(
        async (term: string) =>
            searchLedgerAccounts({
                variables: {
                    beneficiaryName: term,
                    ledgerId,
                    ledgerAccountTypes,
                },
                fetchPolicy: 'no-cache',
            }).then(({ data }) =>
                (data?.searchLedgerAccounts ?? []).map(
                    (ledgerAccount): LedgerAccountSelectOption => ({
                        label: ledgerAccountLabel(ledgerAccount, locale),
                        value: ledgerAccount.id,
                        ledgerAccount,
                    })
                )
            ),
        [ledgerAccountTypes, ledgerId, locale, searchLedgerAccounts]
    )

    const onChangeLedgerAccount = useCallback<
        (value: unknown, option: LedgerAccountSelectOption | LedgerAccountSelectOption[]) => void
    >(
        (_, option) => {
            if (!isArray(option)) {
                onChange?.(option.ledgerAccount)
            }
        },
        [onChange]
    )

    return (
        <RemoteSelect<LedgerAccountSelectOption>
            fetchData={search}
            loading={loading}
            onChange={onChangeLedgerAccount}
        />
    )
}

type LedgerAccountSelectOption = {
    label: string
    value: string
    ledgerAccount: LedgerAccount
}

type LedgerAccountSearch = (term: string) => Promise<LedgerAccountSelectOption[]>
