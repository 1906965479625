import { FC } from '@publica/ui-common-utils'

import { useRenderedAmount, useRenderedPercentage } from '../../lib'

type LedgerAmountProps = {
    amount: number
    mode?: 'amount' | 'percentage'
}

export const LedgerAmount: FC<LedgerAmountProps> = ({ amount, mode = 'amount' }) => {
    switch (mode) {
        case 'amount':
            return <LedgerAmountAsAmount amount={amount} />
        case 'percentage':
            return <LedgerAmountAsPercentage amount={amount} />
    }
}

type LedgerAmountAsAmountProps = {
    amount: number
}

const LedgerAmountAsAmount: FC<LedgerAmountAsAmountProps> = ({ amount }) => {
    const formattedAmount = useRenderedAmount(amount)
    return <>{formattedAmount}</>
}

type LedgerAmountAsPercentageProps = {
    amount: number
}

const LedgerAmountAsPercentage: FC<LedgerAmountAsPercentageProps> = ({ amount }) => {
    const formattedAmount = useRenderedPercentage(amount)
    return <>{formattedAmount}</>
}
