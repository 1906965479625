import { Tag } from 'antd'
import { ColumnType } from 'antd/lib/table'
import { ExpandableConfig } from 'antd/lib/table/interface'
import orderBy from 'lodash/orderBy'
import { DateTime } from 'luxon'
import { useMemo } from 'react'

import { ledgerTransactionStatusLookup, ledgerTransactionTypeLookup } from '@publica/lookups'
import { createUseTranslation, useCurrentLocale } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { FilterColumnType, FilterTable, filterByDate, icons } from '@publica/ui-web-components'
import { filterItemsFromLookup } from '@publica/ui-web-utils'

import type { LedgerTransactionStatus } from '../../../../../data'
import { NotApplicable } from '../../../../components'
import { formatTransactionSequence } from '../../../../lib'
import { LedgerEntryTable } from './LedgerEntryTable'
import { LedgerTransactionDescription } from './LedgerTransactionDescription'
import { LedgerTransaction } from './types'

export type LedgerTransactionTableProps = {
    loading?: boolean
    ledgerTransactions: LedgerTransaction[]
}

const useLedgerTransactionTableTranslation = createUseTranslation({
    FR: {
        type: 'Type',
        date: 'Date',
        comment: ' Commentaire',
        historical: `Reprise de l'existant`,
        sequence: '#',
        status: 'État',
        description: 'Description',
    },
    EN: {
        type: 'Type',
        date: 'Date',
        comment: ' Comment',
        historical: 'Historical',
        sequence: '#',
        status: 'Status',
        description: 'Description',
    },
})

export const LedgerTransactionTable: FC<LedgerTransactionTableProps> = ({ loading = false, ledgerTransactions }) => {
    const sortedTransactions = useMemo(
        () => orderBy(ledgerTransactions, ['status', 'sequence', 'internalSequence'], ['desc', 'desc', 'desc']),
        [ledgerTransactions]
    )
    const locale = useCurrentLocale()
    const { t } = useLedgerTransactionTableTranslation()

    const columns = useMemo<(FilterColumnType<LedgerTransaction> | ColumnType<LedgerTransaction>)[]>(
        () => [
            {
                title: t('sequence'),
                render: (_, transaction) =>
                    formatTransactionSequence(transaction.sequence, { skipHash: true }) ?? <NotApplicable />,
                width: 40,
            },
            {
                title: t('date'),
                render: (_, transaction) => transaction.occurredAt.toLocaleString(DateTime.DATE_SHORT),
                ...filterByDate('occurredAt'),
                width: 100,
            },
            {
                title: t('type'),
                render: (_, transaction) => ledgerTransactionTypeLookup.labelForKeyAndLocale(transaction.type, locale),
                filters: filterItemsFromLookup(ledgerTransactionTypeLookup),
                onFilter: (value, transaction) => transaction.type === value,
                width: 300,
            },
            {
                title: t('description'),
                render: (_, transaction) => <LedgerTransactionDescription transaction={transaction} />,
            },
            {
                title: t('status'),
                render: (_, transaction) => <LedgerTransactionStatus transaction={transaction} />,
                width: 120,
            },
        ],
        [t, locale]
    )

    const expandable = useMemo<ExpandableConfig<LedgerTransaction>>(
        () => ({
            expandedRowRender: transaction => <LedgerEntryTable transaction={transaction} />,
        }),
        []
    )

    return (
        <FilterTable<LedgerTransaction>
            dataSource={sortedTransactions}
            rowKey="id"
            loading={loading}
            columns={columns}
            expandable={expandable}
        />
    )
}

type LedgerTransactionStatusProps = {
    transaction: {
        status: LedgerTransactionStatus
    }
}

const LedgerTransactionStatus: FC<LedgerTransactionStatusProps> = ({ transaction }) => {
    const locale = useCurrentLocale()
    const label = ledgerTransactionStatusLookup.labelForKeyAndLocale(transaction.status, locale)

    switch (transaction.status) {
        case 'COMMITTED':
            return (
                <Tag icon={icons.Done} color="green">
                    {label}
                </Tag>
            )
        case 'UNCOMMITTED':
            return <Tag icon={icons.Waiting}>{label}</Tag>
    }
}
