import { Card } from 'antd'

import { FC } from '@publica/ui-common-utils'
import { ControlStrip } from '@publica/ui-web-components'

import { useGetLedgersQuery } from '../../../../data'
import { LedgerHeader } from '../../../components'
import { LedgerTable } from './LedgerTable'
import { NewLedgerControl } from './NewLedgerControl'

const title = <LedgerHeader />

export const LedgerIndex: FC = () => {
    const { loading, data } = useGetLedgersQuery({ pollInterval: __SLOW_POLLING__ })

    const ledgers = data?.ledgers ?? []

    return (
        <Card title={title}>
            <LedgerControls />
            <LedgerTable loading={loading} ledgers={ledgers} />
        </Card>
    )
}

const LedgerControls: FC = () => (
    <ControlStrip>
        <NewLedgerControl />
    </ControlStrip>
)
