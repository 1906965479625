import { InternalRefetchQueriesInclude } from '@apollo/client'
import { useCallback } from 'react'

import { FC } from '@publica/ui-common-utils'
import { ControlledForm, FormValuesWithRequired, useConnectControlledForm } from '@publica/ui-web-utils'

import { LedgerAccountType, useCreateEmissionLedgerTransactionMutation } from '../../../../../../data'
import { CreditOnlyTransactionForm, CreditOnlyTransactionFormValues } from '../components'

type EmissionFormProps = {
    ledgerId: string
    form: ControlledForm<string | undefined, EmissionFormValues, EmissionFormRequiredValues>
    refetchQueries?: InternalRefetchQueriesInclude
}

type EmissionFormValues = CreditOnlyTransactionFormValues
type EmissionFormRequiredValues = FormValuesWithRequired<
    EmissionFormValues,
    'amount' | 'assetType' | 'creditorAccount' | 'occurredAt'
>

export const EmissionForm: FC<EmissionFormProps> = ({ ledgerId, form, refetchQueries }) => {
    const [createEmissionLedgerTransaction, { loading }] = useCreateEmissionLedgerTransactionMutation()

    const submit = useCallback(
        async (values: EmissionFormRequiredValues): Promise<string | undefined> => {
            const { occurredAt, comment, amount, creditorAccount, assetType } = values

            const transaction = {
                context: {
                    occurredAt,
                    comment,
                },
                amount,
                creditorAccountId: creditorAccount.id,
                assetTypeId: assetType.id,
            }

            return createEmissionLedgerTransaction({
                variables: {
                    ledgerId,
                    transaction,
                },
                refetchQueries,
            }).then(({ data }) => data?.createEmissionLedgerTransaction.id)
        },
        [createEmissionLedgerTransaction, ledgerId, refetchQueries]
    )

    useConnectControlledForm(form, submit, loading)

    return (
        <CreditOnlyTransactionForm
            ledgerAccountTypes={emissionLedgerAccountTypes}
            ledgerId={ledgerId}
            form={form.form}
        />
    )
}

const emissionLedgerAccountTypes: LedgerAccountType[] = ['PEA', 'PEA_PME', 'STANDARD']
