import { FC } from 'react'

import { LedgerAmount } from '../LedgerAmount'
import { LedgerAssetHoldershipLabel } from '../LedgerAssetHoldershipLabel'

export type LedgerBalanceLabelProps = {
    name: string
    amount: number
} & (
    | { holdershipType: 'FULL_OWNERSHIP' }
    | {
          holdershipType: 'BARE_OWNERSHIP' | 'USUFRUCT' | 'BOTH'
          dismemberment: {
              transaction: {
                  sequence?: number | null
              }
              bareOwnershipHolder: {
                  beneficiary: {
                      legalEntity: {
                          name: string
                      }
                  }
              }
              usufructHolder: {
                  beneficiary: {
                      legalEntity: {
                          name: string
                      }
                  }
              }
          }
      }
)

export const LedgerBalanceLabel: FC<LedgerBalanceLabelProps> = props => {
    const { amount, name } = props

    return (
        <>
            {name} (
            <LedgerAmount amount={amount} />, <LedgerAssetHoldershipLabel {...props} />)
        </>
    )
}
