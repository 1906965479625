import { useMemo } from 'react'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'

import { useRenderedAmount } from '../../../../../lib'
import { LedgerEntry, LedgerTransaction } from '../types'

type LedgerTransactionDescriptionProps = {
    transaction: LedgerTransaction
}

export const LedgerTransactionDescription: FC<LedgerTransactionDescriptionProps> = ({ transaction }) =>
    useMemo(() => {
        switch (transaction.type) {
            case 'SUBSCRIPTION_CASH':
            case 'SUBSCRIPTION_EQUITY':
                return <LedgerSubscriptionTransactionDescription transaction={transaction} />
            case 'CANCELLATION':
                return <LedgerCancellationTransactionDescription transaction={transaction} />
            case 'EMISSION':
                return <LedgerEmissionTransactionDescription transaction={transaction} />
            case 'TRANSFER_CASH':
            case 'TRANSFER_EQUITY':
                return <LedgerTransferTransactionDescription transaction={transaction} />
            case 'CONVERSION':
                return <LedgerConversionTransactionDescription transaction={transaction} />
            case 'DONATION_DISMEMBERMENT':
                return <LedgerDonationDismembermentTransactionDescription transaction={transaction} />
            case 'PLEDGE':
                return <LedgerPledgeTransactionDescription transaction={transaction} />
            case 'PLEDGE_RELEASE':
                return <LedgerPledgeReleaseTransactionDescription transaction={transaction} />
            case 'DONATION_FULL_OWNERSHIP':
                return <LedgerDonationFullOwnershipTransactionDescription transaction={transaction} />
            case 'RECONSTITUTION':
                return <LedgerReconstitutionTransactionDescription transaction={transaction} />
            case 'STOCK_SPLIT':
                return <LedgerStockSplitTransactionDescription transaction={transaction} />
            case 'REVERSE_STOCK_SPLIT':
                return <LedgerReverseStockSplitTransactionDescription transaction={transaction} />
        }
    }, [transaction])

const LedgerSubscriptionTransactionDescription: FC<LedgerTransactionDescriptionProps> = ({ transaction }) => {
    const { t } = useLedgerSubscriptionTransactionDescriptionTranslation()
    const type = transaction.type as 'SUBSCRIPTION_CASH' | 'SUBSCRIPTION_EQUITY'
    return <>{t(type, useLabelInfoFromEntry(transaction.entries[0]!))}</>
}

const useLedgerSubscriptionTransactionDescriptionTranslation = createUseTranslation({
    FR: {
        SUBSCRIPTION_CASH_one: `Souscription de {{amount}} {{asset}} en numéraire au profit de {{beneficiary}}`,
        SUBSCRIPTION_CASH_other: 'Souscription de {{amount}} {{asset}}s en numéraire au profit de {{beneficiary}}',
        SUBSCRIPTION_EQUITY_one: `Souscription de {{amount}} {{asset}} via apport de titres au profit de {{beneficiary}}`,
        SUBSCRIPTION_EQUITY_other: `Souscription de {{amount}} {{asset}}s via apport de titres au profit de {{beneficiary}}`,
    },
    EN: {
        SUBSCRIPTION_CASH_one: `Cash subscription of {{amount}} {{asset}} for {{beneficiary}}`,
        SUBSCRIPTION_CASH_other: `Cash subscription of {{amount}} {{asset}}s for {{beneficiary}}`,
        SUBSCRIPTION_EQUITY_one: `Equity subscription of {{amount}} {{asset}} for {{beneficiary}}`,
        SUBSCRIPTION_EQUITY_other: `Equity subscription of {{amount}} {{asset}}s for {{beneficiary}}`,
    },
})

const LedgerCancellationTransactionDescription: FC<LedgerTransactionDescriptionProps> = ({ transaction }) => {
    const { t } = useLedgerCancellationTransactionDescriptionTranslation()
    return <>{t('cancellation', useLabelInfoFromEntry(transaction.entries[0]!))}</>
}

const useLedgerCancellationTransactionDescriptionTranslation = createUseTranslation({
    FR: {
        cancellation_one: `Annulation de {{amount}} {{asset}} detenu par {{beneficiary}}`,
        cancellation_other: `Annulation de {{amount}} {{asset}}s detenus par {{beneficiary}}`,
    },
    EN: {
        cancellation_one: `Cancellation of {{amount}} {{asset}} held by {{beneficiary}}`,
        cancellation_other: `Cancellation of {{amount}} {{asset}}s held by {{beneficiary}}`,
    },
})

const LedgerEmissionTransactionDescription: FC<LedgerTransactionDescriptionProps> = ({ transaction }) => {
    const { t } = useLedgerEmissionTransactionDescriptionTranslation()
    return <>{t('emission', useLabelInfoFromEntry(transaction.entries[0]!))}</>
}

const useLedgerEmissionTransactionDescriptionTranslation = createUseTranslation({
    FR: {
        emission_one: `Emission de {{amount}} nouveau {{asset}} au profit de {{beneficiary}}`,
        emission_other: `Emission de {{amount}} nouveaux {{asset}}s au profit de {{beneficiary}}`,
    },
    EN: {
        emission_one: `Emission of {{amount}} new {{asset}} for {{beneficiary}}`,
        emission_other: `Emission of {{amount}} new {{asset}}s for {{beneficiary}}`,
    },
})

const LedgerTransferTransactionDescription: FC<LedgerTransactionDescriptionProps> = ({ transaction }) => {
    const { t } = useLedgerTransferTransactionDescriptionTranslation()

    const type = transaction.type as 'TRANSFER_CASH' | 'TRANSFER_EQUITY'
    const { asset, beneficiary: debitor } = useLabelInfoFromEntry(transaction.entries[0]!)
    const { count, beneficiary: creditor, amount } = useLabelInfoFromEntry(transaction.entries[1]!)
    return <>{t(type, { asset, debitor, creditor, count, amount })}</>
}

const useLedgerTransferTransactionDescriptionTranslation = createUseTranslation({
    FR: {
        TRANSFER_CASH_one: `Transfert en numéraire de {{amount}} {{asset}} entre {{debitor}} et {{creditor}}`,
        TRANSFER_CASH_other: `Transfert en numéraire de {{amount}} {{asset}}s entre {{debitor}} et {{creditor}}`,
        TRANSFER_EQUITY_one: `Transfert de {{amount}} {{asset}} via apport de titres entre {{debitor}} et {{creditor}}`,
        TRANSFER_EQUITY_other: `Transfert en numéraire de {{amount}} {{asset}}s via apport de titres entre {{debitor}} et {{creditor}}`,
    },
    EN: {
        TRANSFER_CASH_one: `Cash transfer of {{amount}} {{asset}} between {{debitor}} and {{creditor}}`,
        TRANSFER_CASH_other: `Cash transfer of {{amount}} {{asset}}s between {{debitor}} and {{creditor}}`,
        TRANSFER_EQUITY_one: `Equity transfer of {{amount}} {{asset}} between {{debitor}} and {{creditor}}`,
        TRANSFER_EQUITY_other: `Equity transfer of {{amount}} {{asset}}s between {{debitor}} and {{creditor}}`,
    },
})

const LedgerConversionTransactionDescription: FC<LedgerTransactionDescriptionProps> = ({ transaction }) => {
    const { t } = useLedgerConversionTransactionDescriptionTranslation()

    const beneficiary = transaction.entries[0]?.account.beneficiary.legalEntity.name
    const sourceAmount = Math.abs(transaction.entries[0]!.amount)
    const source = transaction.entries[0]!.assetType.name
    const targetAmount = Math.abs(transaction.entries[1]!.amount)
    const target = transaction.entries[1]!.assetType.name

    return <>{t('conversion', { beneficiary, source, sourceAmount, targetAmount, target })}</>
}

const useLedgerConversionTransactionDescriptionTranslation = createUseTranslation({
    FR: {
        conversion: `Conversion de {{sourceAmount}} {{source}} en {{targetAmount}} {{target}} pour {{beneficiary}}`,
    },
    EN: {
        conversion: `Conversion of {{sourceAmount}} {{source}} to {{targetAmount}} {{target}} for {{beneficiary}}`,
    },
})

const LedgerDonationDismembermentTransactionDescription: FC<LedgerTransactionDescriptionProps> = ({ transaction }) => {
    const { t } = useLedgerDonationDismembermentTransactionDescriptionTranslation()
    const { asset, beneficiary: firstBeneficiary } = useLabelInfoFromEntry(transaction.entries[0]!)
    const { count, beneficiary: secondBeneficiary, amount } = useLabelInfoFromEntry(transaction.entries[1]!)
    return <>{t('donation', { count, asset, firstBeneficiary, secondBeneficiary, amount })}</>
}

const useLedgerDonationDismembermentTransactionDescriptionTranslation = createUseTranslation({
    FR: {
        donation_one: `Donation avec démembrement de {{amount}} {{asset}} entre {{firstBeneficiary}} et {{secondBeneficiary}}`,
        donation_other: `Donation avec démembrement de {{amount}} {{asset}}s entre {{firstBeneficiary}} et {{secondBeneficiary}}`,
    },
    EN: {
        donation_one: `Donation with dismemberment of {{amount}} {{asset}} between {{firstBeneficiary}} and {{secondBeneficiary}}`,
        donation_other: `Donation with dismemberment of {{amount}} {{asset}}s between {{firstBeneficiary}} and {{secondBeneficiary}}`,
    },
})

const LedgerPledgeTransactionDescription: FC<LedgerTransactionDescriptionProps> = ({ transaction }) => {
    const { t } = useLedgerPledgeTransactionDescriptionTranslation()
    return <>{t('pledge', useLabelInfoFromEntry(transaction.entries[0]!))}</>
}

const useLedgerPledgeTransactionDescriptionTranslation = createUseTranslation({
    FR: {
        pledge_one: `Nantissement de {{amount}} {{asset}} detenu par {{beneficiary}}`,
        pledge_other: `Nantissement de {{amount}} {{asset}}s detenus par {{beneficiary}}`,
    },
    EN: {
        pledge_one: `Pledge of {{amount}} {{asset}} held by {{beneficiary}}`,
        pledge_other: `Pledge of {{amount}} {{asset}}s held by {{beneficiary}}`,
    },
})

const LedgerPledgeReleaseTransactionDescription: FC<LedgerTransactionDescriptionProps> = ({ transaction }) => {
    const { t } = useLedgerPledgeReleaseTransactionDescriptionTranslation()
    return <>{t('pledge', useLabelInfoFromEntry(transaction.entries[0]!))}</>
}

const useLedgerPledgeReleaseTransactionDescriptionTranslation = createUseTranslation({
    FR: {
        pledge_one: `Main levée de nantissement de {{amount}} {{asset}} detenu par {{beneficiary}}`,
        pledge_other: `Main levée de nantissement de {{amount}} {{asset}}s detenus par {{beneficiary}}`,
    },
    EN: {
        pledge_one: `Pledge release of {{amount}} {{asset}} held by {{beneficiary}}`,
        pledge_other: `Pledge release of {{amount}} {{asset}}s held by {{beneficiary}}`,
    },
})

const LedgerDonationFullOwnershipTransactionDescription: FC<LedgerTransactionDescriptionProps> = ({ transaction }) => {
    const { t } = useLedgerDonationFullOwnershipTransactionDescriptionTranslation()
    const { asset, beneficiary: firstBeneficiary } = useLabelInfoFromEntry(transaction.entries[0]!)
    const { count, beneficiary: secondBeneficiary, amount } = useLabelInfoFromEntry(transaction.entries[1]!)
    return <>{t('donation', { count, asset, firstBeneficiary, secondBeneficiary, amount })}</>
}

const useLedgerDonationFullOwnershipTransactionDescriptionTranslation = createUseTranslation({
    FR: {
        donation_one: `Donation de pleine propriété de {{amount}} {{asset}} entre {{firstBeneficiary}} et {{secondBeneficiary}}`,
        donation_other: `Donation de pleine propriété de {{amount}} {{asset}}s entre {{firstBeneficiary}} et {{secondBeneficiary}}`,
    },
    EN: {
        donation_one: `Donation of full ownership of {{amount}} {{asset}} between {{firstBeneficiary}} and {{secondBeneficiary}}`,
        donation_other: `Donation of full ownership of {{amount}} {{asset}}s between {{firstBeneficiary}} and {{secondBeneficiary}}`,
    },
})

const LedgerStockSplitTransactionDescription: FC<LedgerTransactionDescriptionProps> = () => {
    const { t } = useLedgerStockSplitTransactionDescriptionTranslation()

    return <>{t('stock_split')}</>
}

const useLedgerStockSplitTransactionDescriptionTranslation = createUseTranslation({
    FR: {
        stock_split: 'Division de titres',
    },
    EN: {
        stock_split: 'Stock split',
    },
})

const LedgerReverseStockSplitTransactionDescription: FC<LedgerTransactionDescriptionProps> = () => {
    const { t } = useLedgerReverseStockSplitTransactionDescriptionTranslation()

    return <>{t('reverse_stock_split')}</>
}

const useLedgerReverseStockSplitTransactionDescriptionTranslation = createUseTranslation({
    FR: {
        reverse_stock_split: 'Regroupement de titres',
    },
    EN: {
        reverse_stock_split: 'Reverse stock split',
    },
})

const LedgerReconstitutionTransactionDescription: FC<LedgerTransactionDescriptionProps> = ({ transaction }) => {
    const { t } = useLedgerReconstitutionTransactionDescriptionTranslation()
    return <>{t('reconstitution', useLabelInfoFromEntry(transaction.entries[2]!))}</>
}

const useLedgerReconstitutionTransactionDescriptionTranslation = createUseTranslation({
    FR: {
        reconstitution_one: `Reconstitution de {{amount}} {{asset}} démembré detenu par {{beneficiary}}`,
        reconstitution_other: `Reconstitution de {{amount}} {{asset}}s démembrés detenus par {{beneficiary}}`,
    },
    EN: {
        reconstitution_one: `Reconstitution of {{amount}} {{asset}} held by {{beneficiary}}`,
        reconstitution_other: `Reconstitution of {{amount}} {{asset}}s held by {{beneficiary}}`,
    },
})

const useLabelInfoFromEntry = (entry: LedgerEntry) => {
    const beneficiary = entry.account.beneficiary.legalEntity.name
    const count = entry.amount
    const amount = useRenderedAmount(entry.amount)
    const asset = entry.assetType.name

    return {
        beneficiary,
        count,
        asset,
        amount,
    }
}
