import { Card } from 'antd'
import { useMemo } from 'react'

import { FC } from '@publica/ui-common-utils'
import { ControlStrip } from '@publica/ui-web-components'

import { useGetLedgerAssetTypesQuery } from '../../../../data'
import { CreateLedgerAssetTypeControl } from './CreateLedgerAssetTypeControl'
import { LedgerAssetTypeTable } from './LedgerAssetTypeTable'

type LedgerAssetTypeProps = {
    ledger: { id: string }
}

export const LedgerAssetTypeIndex: FC<LedgerAssetTypeProps> = ({ ledger }) => {
    const ledgerId = ledger.id

    const { loading, data } = useGetLedgerAssetTypesQuery({
        pollInterval: __SLOW_POLLING__,
        variables: {
            ledgerId,
        },
    })

    const ledgerAssetTypes = useMemo(() => data?.ledger?.assetTypes ?? [], [data?.ledger?.assetTypes])

    return (
        <Card>
            <LedgerAssetTypeControls ledgerId={ledgerId} />
            <LedgerAssetTypeTable loading={loading} ledgerAssetTypes={ledgerAssetTypes} />
        </Card>
    )
}

type LedgerAssetTypeControlsProps = {
    ledgerId: string
}

const LedgerAssetTypeControls: FC<LedgerAssetTypeControlsProps> = ({ ledgerId }) => (
    <ControlStrip>
        <CreateLedgerAssetTypeControl ledgerId={ledgerId} />
    </ControlStrip>
)
