import { Route, Routes } from 'react-router-dom'

import { FC } from '@publica/ui-common-utils'

import { LedgerIndex } from './LedgerIndex'
import { ShowLedger } from './ShowLedger'

const ledgerIndex = <LedgerIndex />
const showLedger = <ShowLedger />

export const Ledgers: FC = () => (
    <Routes>
        <Route index element={ledgerIndex} />
        <Route path=":ledgerId/*" element={showLedger} />
    </Routes>
)
