import { createUseStyles } from 'react-jss'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { colors } from '@publica/ui-common-styles'
import { FC } from '@publica/ui-common-utils'

const useNotApplicableTranslation = createUseTranslation({
    FR: {
        notApplicable: 'N/A',
    },
    EN: {
        notApplicable: 'N/A',
    },
})

const useNotApplicableStyles = createUseStyles({
    notApplicable: {
        color: colors.grey6,
    },
})

export const NotApplicable: FC = () => {
    const { t } = useNotApplicableTranslation()
    const styles = useNotApplicableStyles()

    return <span className={styles.notApplicable}>{t('notApplicable')}</span>
}
