import { Card, Col, Row } from 'antd'

import { FC } from '@publica/ui-common-utils'
import { utils } from '@publica/ui-web-styles'

import { useGetLedgerTransactionsQuery } from '../../../../data'
import { LedgerTransactionTable } from './LedgerTransactionTable'
import { NewLedgerTransactionControl } from './NewLedgerTransactionControl'

type LedgerTransactionIndexProps = {
    ledger: { id: string }
}

export const LedgerTransactionIndex: FC<LedgerTransactionIndexProps> = ({ ledger }) => {
    const ledgerId = ledger.id

    // TODO(ledger-ui, transactions): Pagination
    // TODO(ledger-ui, transactions): Hidden ledger entries
    // TODO(ledger-ui, transactions): Filter by participant
    // TODO(ledger-ui, transactions): filter by asset type
    // TODO(ledger-ui): delete uncommitted
    // TODO(ledger-ui): commit

    const { loading, data } = useGetLedgerTransactionsQuery({
        pollInterval: __SLOW_POLLING__,
        variables: {
            ledgerId,
        },
    })

    const ledgerTransactions = data?.ledger?.transactions ?? []

    return (
        <Card>
            <LedgerTransactionControls ledgerId={ledgerId} />
            <LedgerTransactionTable loading={loading} ledgerTransactions={ledgerTransactions} />
        </Card>
    )
}

type LedgerTransactionControlsProps = {
    ledgerId: string
}

const LedgerTransactionControls: FC<LedgerTransactionControlsProps> = ({ ledgerId }) => {
    const styles = utils.useControlsStyles()

    return (
        <Row justify="end" className={styles.controls}>
            <Col>
                <NewLedgerTransactionControl ledgerId={ledgerId} />
            </Col>
        </Row>
    )
}
