import { createUseTranslation } from '@publica/ui-common-i18n'

export const useTransactionFormTranslations = createUseTranslation({
    FR: {
        creditorAccount: `Compte à créditer`,
        creditAmount: 'Quantité à créditer',
        debitorAccount: `Compte à débiter`,
        debitAmount: 'Quantité à débiter',
        assetType: 'Actif',
        amount: 'Nombre',
        account: 'Compte',
        accountsMustDiffer: 'Les comptes doivent être différents',
        usufructAccount: 'Compte usufruiter',
        bareOwnershipAccount: 'Compte nue-propriété',
        insufficientBalance: 'Le nombre est supérieur à la détention actuelle',
    },
    EN: {
        creditorAccount: 'Account to credit',
        creditAmount: 'Amount to credit',
        debitorAccount: `Account to debit`,
        debitAmount: 'Amount to debit',
        assetType: 'Asset',
        amount: 'Nombre',
        account: 'Account',
        accountsMustDiffer: 'Accounts must be different',
        usufructAccount: 'Usufruct account',
        bareOwnershipAccount: 'Bare ownership account',
        insufficientBalance: 'The amount is greater than the current balance',
    },
})
