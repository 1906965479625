import { Select, Spin } from 'antd'
import type { BaseOptionType, DefaultOptionType, SelectProps } from 'antd/es/select'
import debounce from 'lodash/debounce'
import sortBy from 'lodash/sortBy'
import { useCallback, useMemo, useRef, useState } from 'react'

export type RemoteSelectProps<O extends DefaultOptionType | BaseOptionType = DefaultOptionType> = Omit<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    SelectProps<any, O>,
    'options' | 'children'
> & {
    fetchData: (search: string) => Promise<O[]>
    debounceTimeout?: number
}

export const RemoteSelect = <O extends DefaultOptionType | BaseOptionType = DefaultOptionType>({
    fetchData,
    debounceTimeout = 800,
    ...props
}: RemoteSelectProps<O>) => {
    const [fetching, setFetching] = useState(false)
    const [showIcon, setShowIcon] = useState(false)
    const [options, setOptions] = useState<O[]>([])
    const fetchRef = useRef(0)

    const onFocus = useCallback(() => {
        setShowIcon(true)
    }, [])

    const onBlur = useCallback(() => {
        setShowIcon(false)
    }, [])

    const debounceFetcher = useMemo(() => {
        const loadOptions = (value: string) => {
            if (value.trim() === '') {
                setOptions([])
                return
            }

            fetchRef.current += 1
            const fetchId = fetchRef.current
            setOptions([])
            setFetching(true)

            void fetchData(value).then(newOptions => {
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return
                }

                setOptions(sortBy(newOptions, o => o.label))
                setFetching(false)
            })
        }

        return debounce(loadOptions, debounceTimeout)
    }, [fetchData, debounceTimeout])

    return (
        <Select
            labelInValue
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? smallSpinner : null}
            {...props}
            options={options}
            showSearch
            loading={fetching}
            showArrow={showIcon}
            onFocus={onFocus}
            onBlur={onBlur}
        />
    )
}

const smallSpinner = <Spin size="small" />
