import { InternalRefetchQueriesInclude } from '@apollo/client'
import { Divider, Form } from 'antd'
import { ReactElement, useCallback, useMemo, useState } from 'react'

import { ledgerTransactionTypeLookup } from '@publica/lookups'
import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { LookupSelect } from '@publica/ui-web-components'
import { ControlledForm, ControlledFormWithElement, useControlledForm } from '@publica/ui-web-utils'

import { LedgerTransactionType } from '../../../../data'
import {
    CancellationForm,
    ConversionForm,
    EmissionForm,
    PledgeForm,
    PledgeReleaseForm,
    SubscriptionForm,
    TransferForm,
} from './forms'
import { DonationDismembermentForm } from './forms/DonationDismembermentForm'
import { DonationFullOwnershipForm } from './forms/DonationFullOwnershipForm'
import { ReconstitutionForm } from './forms/ReconstitutionForm'

type NewLedgerTransactionFormProps = {
    ledgerId: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    form: ControlledForm<string | undefined, any>
    refetchQueries?: InternalRefetchQueriesInclude
}

const NewLedgerTransactionForm: FC<NewLedgerTransactionFormProps> = ({ form, ledgerId, refetchQueries }) => {
    const { t } = useNewLedgerTransactionFormTranslation()

    const [type, setType] = useState<LedgerTransactionType | undefined>(undefined)

    const onTypeChange = useCallback((value: LedgerTransactionType) => {
        setType(value)
    }, [])

    const transactionSubForm = useMemo((): ReactElement | null => {
        if (type === undefined) {
            return null
        }

        const props = {
            form,
            ledgerId,
            refetchQueries,
        } as const

        switch (type) {
            case 'SUBSCRIPTION_CASH':
            case 'SUBSCRIPTION_EQUITY':
                return <SubscriptionForm {...props} type={type} />
            case 'CANCELLATION':
                return <CancellationForm {...props} />
            case 'EMISSION':
                return <EmissionForm {...props} />
            case 'TRANSFER_CASH':
            case 'TRANSFER_EQUITY':
                return <TransferForm {...props} type={type} />
            case 'CONVERSION':
                return <ConversionForm {...props} />
            case 'PLEDGE':
                return <PledgeForm {...props} />
            case 'PLEDGE_RELEASE':
                return <PledgeReleaseForm {...props} />
            case 'DONATION_FULL_OWNERSHIP':
                return <DonationFullOwnershipForm {...props} />
            case 'DONATION_DISMEMBERMENT':
                return <DonationDismembermentForm {...props} />
            case 'RECONSTITUTION':
                return <ReconstitutionForm {...props} />
            // FIXME(ledger-ui): reverse stock split, stock split
            case 'STOCK_SPLIT':
            case 'REVERSE_STOCK_SPLIT':
                return <>Unsupported Transaction Type: {type}</>
        }
    }, [form, ledgerId, refetchQueries, type])

    return (
        <>
            <Form layout="vertical">
                <Form.Item label={t('type')} required>
                    <LookupSelect lookup={ledgerTransactionTypeLookup} onChange={onTypeChange} />
                </Form.Item>
            </Form>
            {transactionSubForm === null ? null : (
                <>
                    <Divider />
                    {transactionSubForm}
                </>
            )}
        </>
    )
}

const useNewLedgerTransactionFormTranslation = createUseTranslation({
    FR: {
        type: 'Nature de la transaction',
    },
    EN: {
        type: 'Transaction type',
    },
})

type NewLedgerTransactionFormOptions = {
    refetchQueries: InternalRefetchQueriesInclude
}

export const useNewLedgerTransactionForm = (
    ledgerId: string,
    options?: NewLedgerTransactionFormOptions
): ControlledFormWithElement<string | undefined> =>
    useControlledForm({
        render: form => (
            <NewLedgerTransactionForm ledgerId={ledgerId} form={form} refetchQueries={options?.refetchQueries} />
        ),
    })
