import { ExpandableConfig } from 'antd/lib/table/interface'
import { useMemo } from 'react'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { FilterColumnType, FilterTable } from '@publica/ui-web-components'

import { LedgerBeneficiaryTable } from '../LedgerBeneficiaryTable'
import { useAssetTypeAmountsColumns, useCapitalAmountColumns } from '../common'
import { LedgerAssetType, LedgerGroup } from '../types'

type LedgerGroupTableProps = {
    loading?: boolean
    ledgerGroups: LedgerGroup[]
    ledgerAssetTypes: LedgerAssetType[]
}

export const LedgerGroupTable: FC<LedgerGroupTableProps> = ({ ledgerGroups, ledgerAssetTypes, loading }) => {
    const { t } = useLedgerGroupTableTranslation()

    const capitalColumns = useCapitalAmountColumns<LedgerGroup>()
    const assetTypeColumns = useAssetTypeAmountsColumns<LedgerGroup>(ledgerAssetTypes)

    const columns = useMemo<FilterColumnType<LedgerGroup>[]>(
        () => [
            {
                title: t('group'),
                render: (_, group) => group.name,
            },
            {
                title: t('members'),
                render: (_, group) => group.members.length,
            },
            ...capitalColumns,
            ...assetTypeColumns,
        ],
        [assetTypeColumns, capitalColumns, t]
    )

    const expandable: ExpandableConfig<LedgerGroup> = useMemo(
        () => ({
            expandedRowRender: ledgerGroup => (
                <LedgerBeneficiaryTable ledgerBeneficiaries={ledgerGroup.members} ledgerAssetTypes={ledgerAssetTypes} />
            ),
        }),
        [ledgerAssetTypes]
    )

    return (
        <FilterTable<LedgerGroup>
            columns={columns}
            dataSource={ledgerGroups}
            loading={loading}
            expandable={expandable}
        />
    )
}

const useLedgerGroupTableTranslation = createUseTranslation({
    FR: {
        groups: 'Groupes',
        group: 'Groupe',
        members: 'Membres',
    },
    EN: {
        groups: 'Groups',
        group: 'Group',
        members: 'Members',
    },
})
