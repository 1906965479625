import { Form, FormInstance } from 'antd'
import { useEffect, useMemo } from 'react'

import { FC } from '@publica/ui-common-utils'
import { FormRules, useCommonRules } from '@publica/ui-web-utils'

import { LedgerAccountType } from '../../../../../../../data'
import { AmountInput, LedgerAccountSearch, LedgerAssetTypeSelect } from '../../../../../../components'
import { useLedgerAssetTypeEligibilityValidator } from '../../../../../../lib'
import { TransactionContextForm, TransactionContextFormValues } from '../TransactionContextForm'
import { useTransactionFormTranslations } from '../common'

type CreditOnlyTransactionFormProps = {
    ledgerId: string
    form: FormInstance
    ledgerAccountTypes?: LedgerAccountType[]
}

type LedgerAccount = { id: string; type: LedgerAccountType }
type LedgerAssetType = { id: string; eligibleAccountTypes: LedgerAccountType[] }

export type CreditOnlyTransactionFormValues = {
    amount: number | undefined
    creditorAccount: LedgerAccount | undefined
    assetType: LedgerAssetType | undefined
} & TransactionContextFormValues

export const CreditOnlyTransactionForm: FC<CreditOnlyTransactionFormProps> = ({
    ledgerId,
    form,
    ledgerAccountTypes,
}) => {
    const rules = useCommonRules()
    const { t } = useTransactionFormTranslations()
    const assetTypeValidator = useLedgerAssetTypeEligibilityValidator('creditorAccount')

    const validation: FormRules<CreditOnlyTransactionFormValues> = useMemo(
        () => ({
            creditorAccount: rules.required,
            amount: rules.required,
            assetType: [...rules.required, assetTypeValidator],
        }),
        [assetTypeValidator, rules.required]
    )

    const assetType = Form.useWatch('assetType', form)
    const creditorAccount = Form.useWatch('creditorAccount', form)

    useEffect(() => {
        if (assetType !== undefined) {
            void form.validateFields(['assetType'])
        }
    }, [assetType, creditorAccount, form])

    return (
        <Form form={form} layout="vertical">
            <TransactionContextForm>
                <Form.Item name="creditorAccount" label={t('creditorAccount')} rules={validation.creditorAccount}>
                    <LedgerAccountSearch ledgerAccountTypes={ledgerAccountTypes} ledgerId={ledgerId} />
                </Form.Item>
                <Form.Item name="amount" label={t('creditAmount')} rules={validation.amount}>
                    <AmountInput />
                </Form.Item>
                <Form.Item name="assetType" label={t('assetType')} rules={validation.assetType}>
                    <LedgerAssetTypeSelect ledgerId={ledgerId} />
                </Form.Item>
            </TransactionContextForm>
        </Form>
    )
}
