import { Form, FormInstance } from 'antd'

import { FC } from '@publica/ui-common-utils'
import { useCommonRules } from '@publica/ui-web-utils'

import { LedgerAccountType } from '../../../../../../../data'
import { LedgerAccountSearch } from '../../../../../../components'
import { DebitAssetTypeForm, DebitAssetTypeFormValues } from '../DebitAssetTypeForm'
import { DebitableHoldershipTypes } from '../DebitableLedgerAssetTypesSelect'
import { TransactionContextForm, TransactionContextFormValues } from '../TransactionContextForm'
import { useTransactionFormTranslations } from '../common'

type DebitOnlyTransactionFormProps = {
    ledgerId: string
    form: FormInstance<DebitOnlyTransactionFormValues>
    holdershipTypes?: DebitableHoldershipTypes
    ledgerAccountTypes?: LedgerAccountType[]
}

export type DebitOnlyTransactionFormValues = DebitAssetTypeFormValues &
    TransactionContextFormValues & {
        debitorAccount: { id: string } | undefined
    }

export const DebitOnlyTransactionForm: FC<DebitOnlyTransactionFormProps> = ({
    ledgerId,
    form,
    holdershipTypes,
    ledgerAccountTypes,
}) => {
    const rules = useCommonRules()
    const { t } = useTransactionFormTranslations()

    const debitorAccount = Form.useWatch('debitorAccount', form)

    return (
        <Form form={form} layout="vertical">
            <TransactionContextForm>
                <Form.Item name="debitorAccount" label={t('debitorAccount')} rules={rules.required}>
                    <LedgerAccountSearch ledgerAccountTypes={ledgerAccountTypes} ledgerId={ledgerId} />
                </Form.Item>
                <DebitAssetTypeForm
                    holdershipTypes={holdershipTypes}
                    ledgerId={ledgerId}
                    form={form as unknown as FormInstance<DebitAssetTypeFormValues>}
                    debitorAccountId={debitorAccount?.id}
                />
            </TransactionContextForm>
        </Form>
    )
}
