import { useEffect } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'

import { FC } from '@publica/ui-common-utils'
import { Layout, NotFound } from '@publica/ui-web-components'

import { Ledgers } from './pages'

const ledgers = <Ledgers />
const notFound = <NotFound />

export const App: FC = () => {
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        if (location.pathname === '/') {
            navigate('/ledgers')
        }
    }, [location, navigate])

    return (
        <Layout logo={null}>
            <Routes>
                <Route path="/ledgers/*" element={ledgers} />
                <Route path="*" element={notFound} />
            </Routes>
        </Layout>
    )
}

export default App
