import { useMemo } from 'react'
import { Route, Routes } from 'react-router-dom'

import { FC } from '@publica/ui-common-utils'

import { LedgerAccountIndex } from './LedgerAccountIndex'

type LedgerAccountsProps = {
    ledger: { id: string }
}

export const LedgerAccounts: FC<LedgerAccountsProps> = ({ ledger }) => {
    const ledgerAccountIndex = useMemo(() => <LedgerAccountIndex ledger={ledger} />, [ledger])

    return (
        <Routes>
            <Route path="accounts" element={ledgerAccountIndex} />
        </Routes>
    )
}
