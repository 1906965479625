import { ledgerHoldershipTypeAbbreviationLookup } from '@publica/lookups'
import { createUseTranslation, useCurrentLocale } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'

import { formatTransactionSequence } from '../../lib'

type LedgerAssetHoldershipLabelProps =
    | {
          holdershipType: 'FULL_OWNERSHIP'
      }
    | {
          holdershipType: 'BARE_OWNERSHIP' | 'USUFRUCT' | 'BOTH'
          dismemberment: {
              transaction: {
                  sequence?: null | number
              }
              usufructHolder: {
                  beneficiary: {
                      legalEntity: {
                          name: string
                      }
                  }
              }
              bareOwnershipHolder: {
                  beneficiary: {
                      legalEntity: {
                          name: string
                      }
                  }
              }
          }
      }

export const LedgerAssetHoldershipLabel: FC<LedgerAssetHoldershipLabelProps> = props => {
    const { t } = useLedgerAssetHoldershipLabelTranslation()
    const locale = useCurrentLocale()

    if (props.holdershipType === 'FULL_OWNERSHIP') {
        return <>{ledgerHoldershipTypeAbbreviationLookup.labelForKeyAndLocale(props.holdershipType, locale)}</>
    }

    const { holdershipType, dismemberment } = props

    const seq = formatTransactionSequence(dismemberment.transaction.sequence)

    const usufructAbbr = ledgerHoldershipTypeAbbreviationLookup.labelForKeyAndLocale('USUFRUCT', locale)
    const bareOwnershipAbbr = ledgerHoldershipTypeAbbreviationLookup.labelForKeyAndLocale('BARE_OWNERSHIP', locale)

    const usufruct = dismemberment.usufructHolder.beneficiary.legalEntity.name
    const bareOwnership = dismemberment.bareOwnershipHolder.beneficiary.legalEntity.name

    const holdershipAbbreviation =
        holdershipType === 'BOTH'
            ? null
            : `${ledgerHoldershipTypeAbbreviationLookup.labelForKeyAndLocale(holdershipType, locale)},`

    return (
        <>
            {holdershipAbbreviation}{' '}
            {t('dismembermentWith', {
                seq,
                usufruct,
                usufructAbbr,
                bareOwnership,
                bareOwnershipAbbr,
            })}
        </>
    )
}

const useLedgerAssetHoldershipLabelTranslation = createUseTranslation({
    FR: {
        dismembermentWith:
            'démembrement {{seq}} entre {{usufruct}} [{{usufructAbbr}}] et {{bareOwnership}} [{{bareOwnershipAbbr}}]',
    },
    EN: {
        dismembermentWith:
            'démembrement {{seq}} between {{usufruct}} [{{usufructAbbr}}] and {{bareOwnership}} [{{bareOwnershipAbbr}}]',
    },
})
