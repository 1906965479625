import { Card } from 'antd'
import { useMemo } from 'react'

import { FC } from '@publica/ui-common-utils'

// import { CreateLedgerAccountControl } from './CreateLedgerAccountControl'
// import { ControlStrip } from '@publica/ui-web-components'
import { useGetLedgerBalancesQuery } from '../../../../data'
// import { SetHistoricalBalanceControl } from './SetHistoricalBalanceControl'
import { LedgerGroupTable } from './LedgerGroupTable'

type LedgerAccountIndexProps = {
    ledger: { id: string }
}

export const LedgerAccountIndex: FC<LedgerAccountIndexProps> = ({ ledger }) => {
    const ledgerId = ledger.id

    const { loading, data } = useGetLedgerBalancesQuery({
        pollInterval: __SLOW_POLLING__,
        variables: {
            ledgerId,
        },
    })

    const ledgerGroups = useMemo(() => data?.ledger?.groups ?? [], [data?.ledger?.groups])
    const ledgerAssetTypes = useMemo(() => data?.ledger?.assetTypes ?? [], [data?.ledger?.assetTypes])

    // const canCreateHistoricalBalances = ledgerAccounts.length > 0 && ledgerAssetTypes.length > 0

    return (
        <Card>
            {/* <LedgerAccountControls ledgerId={ledgerId} canCreateHistoricalBalances={canCreateHistoricalBalances} /> */}
            <LedgerGroupTable loading={loading} ledgerGroups={ledgerGroups} ledgerAssetTypes={ledgerAssetTypes} />
        </Card>
    )
}

// type LedgerAccountControlsProps = {
//     ledgerId: string
//     canCreateHistoricalBalances: boolean
// }

// const LedgerAccountControls: FC<LedgerAccountControlsProps> = ({ ledgerId }) => (
//     <ControlStrip>
//         <CreateLedgerAccountControl ledgerId={ledgerId} />
//         {/* <SetHistoricalBalanceControl ledgerId={ledgerId} disabled={!canCreateHistoricalBalances} /> */}
//     </ControlStrip>
// )
