import { InternalRefetchQueriesInclude } from '@apollo/client'
import { Form, FormInstance, Select } from 'antd'
import flatMap from 'lodash/flatMap'
import isArray from 'lodash/isArray'
import { ReactNode, useCallback, useEffect, useMemo } from 'react'

import { FC } from '@publica/ui-common-utils'
import {
    ControlledForm,
    FormRules,
    FormValuesWithRequired,
    useCommonRules,
    useConnectControlledForm,
} from '@publica/ui-web-utils'
import { buildIdMap } from '@publica/utils'

import {
    LedgerAccountType,
    ReconstitutionLedgerTransactionCreateInput,
    useCreateReconstitutionLedgerTransactionMutation,
    useGetLedgerAccountBalancesQuery,
} from '../../../../../../data'
import { AmountInput, LedgerAccountSearch } from '../../../../../components'
import { TransactionContextForm, TransactionContextFormValues, useTransactionFormTranslations } from '../components'

type LedgerAssetTypeEligibleForReconstitution = {
    id: string
    assetTypeId: string
    name: ReactNode
    amount: number
    dismemberment: {
        transactionId: string
    }
}

type ReconstitutionFormProps = {
    ledgerId: string
    form: ControlledForm<string | undefined, ReconstitutionFormValues, ReconstitutionFormRequiredValues>
    refetchQueries?: InternalRefetchQueriesInclude
}

type ReconstitutionFormValues = {
    amount: number | undefined
    creditorAccount: { id: string } | undefined
    assetType: LedgerAssetTypeEligibleForReconstitution | undefined
} & TransactionContextFormValues

type ReconstitutionFormRequiredValues = FormValuesWithRequired<
    ReconstitutionFormValues,
    'creditorAccount' | 'occurredAt' | 'assetType' | 'amount'
>

export const ReconstitutionForm: FC<ReconstitutionFormProps> = ({ ledgerId, form, refetchQueries }) => {
    const [createReconstitutionLedgerTransaction, { loading }] = useCreateReconstitutionLedgerTransactionMutation()

    const submit = useCallback(
        async (values: ReconstitutionFormRequiredValues): Promise<string | undefined> => {
            const { occurredAt, comment, creditorAccount, assetType, amount } = values

            const transaction: ReconstitutionLedgerTransactionCreateInput = {
                context: {
                    occurredAt,
                    comment,
                },
                creditorAccountId: creditorAccount.id,
                dismembermentTransactionId: assetType.dismemberment.transactionId,
                amount,
                assetTypeId: assetType.assetTypeId,
            }

            return createReconstitutionLedgerTransaction({
                variables: {
                    ledgerId,
                    transaction,
                },
                refetchQueries,
            }).then(({ data }) => data?.createReconstitutionLedgerTransaction.id)
        },
        [createReconstitutionLedgerTransaction, ledgerId, refetchQueries]
    )

    useConnectControlledForm(form, submit, loading)

    return <ReconstitutionTransactionForm ledgerId={ledgerId} form={form.form} />
}

type ReconstitutionTransactionFormProps = {
    ledgerId: string
    form: FormInstance<ReconstitutionFormValues>
}

const ReconstitutionTransactionForm: FC<ReconstitutionTransactionFormProps> = ({ ledgerId, form }) => {
    const rules = useCommonRules()
    const { t } = useTransactionFormTranslations()

    const creditorAccount = Form.useWatch('creditorAccount', form)
    const assetType = Form.useWatch('assetType', form)
    const amountDisabled = assetType === undefined

    const validation = useMemo<FormRules<ReconstitutionFormValues>>(
        () => ({
            creditorAccount: rules.required,
            amount: [
                ...rules.required,
                {
                    validator: async (_, value: number) => {
                        if (value > (assetType?.amount ?? 0)) {
                            throw new Error(t('insufficientBalance'))
                        }
                    },
                },
            ],
            assetType: rules.required,
        }),
        [assetType?.amount, rules.required, t]
    )

    useEffect(() => {
        if (assetType !== undefined) {
            form.setFieldValue('amount', assetType.amount)
        }
    }, [assetType, form])

    return (
        <Form form={form} layout="vertical">
            <TransactionContextForm>
                <Form.Item name="creditorAccount" label={t('account')} rules={validation.creditorAccount}>
                    <LedgerAccountSearch ledgerAccountTypes={reconstitutionLedgerAccountTypes} ledgerId={ledgerId} />
                </Form.Item>
                <Form.Item name="assetType" label={t('assetType')} rules={validation.assetType}>
                    {creditorAccount?.id === undefined ? (
                        <Select disabled />
                    ) : (
                        <LedgerAssetTypesEligibleForReconstitutionSelect
                            ledgerId={ledgerId}
                            ledgerAccountId={creditorAccount.id}
                        />
                    )}
                </Form.Item>
                <Form.Item name="amount" label={t('amount')} rules={validation.amount}>
                    <AmountInput disabled={amountDisabled} />
                </Form.Item>
            </TransactionContextForm>
        </Form>
    )
}

const reconstitutionLedgerAccountTypes: LedgerAccountType[] = ['STANDARD', 'PEA', 'PEA_PME']

type LedgerAssetTypesEligibleForReconstitutionSelectProps = {
    ledgerId: string
    ledgerAccountId: string
    value?: LedgerAssetTypeEligibleForReconstitution
    onChange?: (value: LedgerAssetTypeEligibleForReconstitution | undefined) => void
}

const LedgerAssetTypesEligibleForReconstitutionSelect: FC<LedgerAssetTypesEligibleForReconstitutionSelectProps> = ({
    ledgerAccountId,
    ledgerId,
    onChange,
}) => {
    const { loading, data } = useGetLedgerAccountBalancesQuery({
        variables: {
            ledgerId,
            ledgerAccountId,
        },
        fetchPolicy: 'no-cache',
    })

    const assetTypes = useMemo(
        () =>
            flatMap(
                data?.ledger?.account.balance.balanceByAssetType ?? [],
                (): LedgerAssetTypeEligibleForReconstitution[] => {
                    // FIXME(ledger-ui): balance asset types
                    return []
                    // const assetTypes: LedgerAssetTypeEligibleForReconstitution[] = []
                    // const { assetType } = balance

                    // const { id: assetTypeId, name } = assetType

                    // for (const subBalance of balance.balances) {
                    //     if (subBalance.__typename === 'LedgerDismemberedBalance') {
                    //         const { usufruct, bareOwnership } = subBalance

                    //         if (usufruct.uncommittedAmount > 0 && bareOwnership.uncommittedAmount > 0) {
                    //             const amount = Math.min(usufruct.uncommittedAmount, bareOwnership.uncommittedAmount)
                    //             const { dismemberment } = subBalance

                    //             assetTypes.push({
                    //                 id: balance.usufruct.id,
                    //                 assetTypeId,
                    //                 amount,
                    //                 name: (
                    //                     <LedgerBalanceLabel
                    //                         name={name}
                    //                         amount={amount}
                    //                         holdershipType="BOTH"
                    //                         dismemberment={dismemberment}
                    //                     />
                    //                 ),
                    //                 dismemberment: {
                    //                     transactionId: dismemberment.transaction.id,
                    //                 },
                    //             })
                    //         }
                    //     }
                    // }

                    // return assetTypes
                }
            ),
        [data?.ledger?.account.balance.balanceByAssetType]
    )

    const assetTypesMap = useMemo(() => buildIdMap(assetTypes), [assetTypes])

    const options = useMemo(
        () =>
            assetTypes.map(balance => ({
                label: balance.name,
                value: balance.id,
            })),
        [assetTypes]
    )

    const onSelectBalance = useCallback(
        (_: OptionType, option: OptionType | OptionType[]) => {
            if (!isArray(option)) {
                const assetTypeBalance = assetTypesMap[option.value]!
                onChange?.(assetTypeBalance)
            }
        },
        [assetTypesMap, onChange]
    )

    return <Select loading={loading} options={options} onChange={onSelectBalance} popupMatchSelectWidth={false} />
}

type OptionType = {
    label: ReactNode
    value: string
}
