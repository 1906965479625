import { Breadcrumb, Col, Row } from 'antd'
import { createUseStyles } from 'react-jss'
import { Link } from 'react-router-dom'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { colors } from '@publica/ui-common-styles'
import { FC } from '@publica/ui-common-utils'

type Legder = {
    id: string
    name: string
}

type LedgerHeaderProps = {
    ledger?: Legder
    ledgersIndex?: boolean
}

const useStyles = createUseStyles({
    headerRight: {
        textAlign: 'right',
    },
    header: {
        fontSize: '1em',
    },
    subHeader: {
        fontSize: '0.8em',
        marginTop: 5,
        color: colors.grey7,
    },
})

const useLedgerHeaderTranslation = createUseTranslation({
    EN: {
        ledgers: 'Ledgers',
    },
    FR: {
        ledgers: 'Registres',
    },
})

export const LedgerHeader: FC<LedgerHeaderProps> = ({ ledger, children, ledgersIndex = true }) => {
    const styles = useStyles()
    const { t } = useLedgerHeaderTranslation()

    return (
        <Row>
            <Col flex={4}>
                <Breadcrumb className={styles.header} separator=">">
                    {ledgersIndex ? (
                        <Breadcrumb.Item>
                            <Link to="/ledgers">{t('ledgers')}</Link>
                        </Breadcrumb.Item>
                    ) : null}
                    {ledger !== undefined ? <Breadcrumb.Item>{ledger.name}</Breadcrumb.Item> : null}
                </Breadcrumb>
            </Col>
            <Col flex="auto" className={styles.headerRight}>
                {children}
            </Col>
        </Row>
    )
}
