import { InternalRefetchQueriesInclude } from '@apollo/client'
import { Form } from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import { useCallback, useMemo } from 'react'

import { FC } from '@publica/ui-common-utils'
import {
    ControlledForm,
    FormRules,
    FormValuesWithRequired,
    useCommonRules,
    useConnectControlledForm,
} from '@publica/ui-web-utils'

import {
    LedgerAccountType,
    PledgeReleaseLedgerTransactionCreateInput,
    useCreatePledgeReleaseLedgerTransactionMutation,
} from '../../../../../../data'
import { LedgerAccountSearch } from '../../../../../components'
import { useDistinctLedgerAccountValidators } from '../../../../../lib'
import {
    DebitAssetTypeForm,
    DebitAssetTypeFormValues,
    DebitOnlyTransactionFormValues,
    TransactionContextForm,
    useTransactionFormTranslations,
} from '../components'

type PledgeReleaseFormProps = {
    ledgerId: string
    form: ControlledForm<string | undefined, PledgeReleaseFormValues, PledgeReleaseFormWithRequiredValues>
    refetchQueries?: InternalRefetchQueriesInclude
}

type PledgeReleaseFormValues = DebitOnlyTransactionFormValues & {
    creditorAccount: DebitOnlyTransactionFormValues['debitorAccount']
}
type PledgeReleaseFormWithRequiredValues = FormValuesWithRequired<
    PledgeReleaseFormValues,
    'creditorAccount' | 'occurredAt' | 'amount' | 'assetType' | 'debitorAccount'
>

export const PledgeReleaseForm: FC<PledgeReleaseFormProps> = ({ ledgerId, form, refetchQueries }) => {
    const [createPledgeReleaseLedgerTransaction, { loading }] = useCreatePledgeReleaseLedgerTransactionMutation()

    const submit = useCallback(
        async (values: PledgeReleaseFormWithRequiredValues): Promise<string | undefined> => {
            const { occurredAt, comment, amount, creditorAccount, debitorAccount, assetType } = values

            const transaction: PledgeReleaseLedgerTransactionCreateInput = {
                context: {
                    occurredAt,
                    comment,
                },
                amount,
                creditorAccountId: creditorAccount.id,
                debitorAccountId: debitorAccount.id,
                assetType,
            }

            return createPledgeReleaseLedgerTransaction({
                variables: {
                    ledgerId,
                    transaction,
                },
                refetchQueries,
            }).then(({ data }) => data?.createPledgeReleaseLedgerTransaction.id)
        },
        [createPledgeReleaseLedgerTransaction, ledgerId, refetchQueries]
    )

    useConnectControlledForm(form, submit, loading)

    return <PledgeReleaseTransactionForm ledgerId={ledgerId} form={form.form} />
}

type PledgeReleaseTransactionFormProps = {
    ledgerId: string
    form: FormInstance<PledgeReleaseFormValues>
}

const PledgeReleaseTransactionForm: FC<PledgeReleaseTransactionFormProps> = ({ form, ledgerId }) => {
    const rules = useCommonRules()
    const { t } = useTransactionFormTranslations()

    const debitorAccount = Form.useWatch('debitorAccount', form)

    const [debitAccountValidator, creditAccountValidator] = useDistinctLedgerAccountValidators(
        'debitorAccount',
        'creditorAccount'
    )

    const validation = useMemo<FormRules<PledgeReleaseFormValues>>(
        () => ({
            debitorAccount: [...rules.required, debitAccountValidator],
            creditorAccount: [...rules.required, creditAccountValidator],
        }),
        [creditAccountValidator, debitAccountValidator, rules.required]
    )

    return (
        <Form form={form} layout="vertical">
            <TransactionContextForm>
                <Form.Item
                    name="debitorAccount"
                    label={t('debitorAccount')}
                    rules={validation.debitorAccount}
                    hasFeedback
                >
                    <LedgerAccountSearch
                        ledgerAccountTypes={pledgeReleaseDebitorLedgerAccountTypes}
                        ledgerId={ledgerId}
                    />
                </Form.Item>
                <Form.Item
                    name="creditorAccount"
                    label={t('creditorAccount')}
                    rules={validation.creditorAccount}
                    hasFeedback
                >
                    <LedgerAccountSearch
                        ledgerAccountTypes={pledgeReleaseCreditorLedgerAccountTypes}
                        ledgerId={ledgerId}
                    />
                </Form.Item>
                <DebitAssetTypeForm
                    form={form as unknown as FormInstance<DebitAssetTypeFormValues>}
                    ledgerId={ledgerId}
                    debitorAccountId={debitorAccount?.id}
                    creditorAccountField="creditorAccount"
                />
            </TransactionContextForm>
        </Form>
    )
}

const pledgeReleaseDebitorLedgerAccountTypes: LedgerAccountType[] = ['PLEDGE']
const pledgeReleaseCreditorLedgerAccountTypes: LedgerAccountType[] = ['PEA', 'PEA_PME', 'STANDARD']
