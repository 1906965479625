import { InternalRefetchQueriesInclude } from '@apollo/client'
import { useCallback } from 'react'

import { FC } from '@publica/ui-common-utils'
import { ControlledForm, FormValuesWithRequired, useConnectControlledForm } from '@publica/ui-web-utils'

import {
    LedgerAccountType,
    useCreateCashSubscriptionLedgerTransactionMutation,
    useCreateEquitySubscriptionLedgerTransactionMutation,
} from '../../../../../../data'
import { CreditOnlyTransactionForm, CreditOnlyTransactionFormValues } from '../components'

type SubscriptionFormProps = {
    ledgerId: string
    form: ControlledForm<string | undefined, SubscriptionFormValues, SubscriptionFormRequiredValues>
    refetchQueries?: InternalRefetchQueriesInclude
    type: 'SUBSCRIPTION_CASH' | 'SUBSCRIPTION_EQUITY'
}

type SubscriptionFormValues = CreditOnlyTransactionFormValues
type SubscriptionFormRequiredValues = FormValuesWithRequired<
    SubscriptionFormValues,
    'amount' | 'assetType' | 'creditorAccount' | 'occurredAt'
>

export const SubscriptionForm: FC<SubscriptionFormProps> = ({ ledgerId, form, refetchQueries, type }) => {
    const [createCashSubscriptionLedgerTransaction, { loading: cashLoading }] =
        useCreateCashSubscriptionLedgerTransactionMutation()
    const [createEquitySubscriptionLedgerTransaction, { loading: equityLoading }] =
        useCreateEquitySubscriptionLedgerTransactionMutation()

    const loading = cashLoading || equityLoading

    const submit = useCallback(
        async (values: SubscriptionFormRequiredValues): Promise<string | undefined> => {
            const { occurredAt, comment, amount, creditorAccount, assetType } = values

            const transaction = {
                context: {
                    occurredAt,
                    comment,
                },
                amount,
                creditorAccountId: creditorAccount.id,
                assetTypeId: assetType.id,
            }

            const args = {
                variables: {
                    ledgerId,
                    transaction,
                },
                refetchQueries,
            } as const

            switch (type) {
                case 'SUBSCRIPTION_CASH':
                    return createCashSubscriptionLedgerTransaction(args).then(({ data }) => {
                        return data?.createCashSubscriptionLedgerTransaction.id
                    })

                case 'SUBSCRIPTION_EQUITY':
                    return createEquitySubscriptionLedgerTransaction(args).then(({ data }) => {
                        return data?.createEquitySubscriptionLedgerTransaction.id
                    })
            }
        },
        [
            createCashSubscriptionLedgerTransaction,
            createEquitySubscriptionLedgerTransaction,
            ledgerId,
            refetchQueries,
            type,
        ]
    )

    useConnectControlledForm(form, submit, loading)

    return (
        <CreditOnlyTransactionForm
            ledgerAccountTypes={subscriptionLedgerAccountTypes}
            ledgerId={ledgerId}
            form={form.form}
        />
    )
}

const subscriptionLedgerAccountTypes: LedgerAccountType[] = ['PEA', 'PEA_PME', 'STANDARD']
