import { useMemo } from 'react'
import { Route, Routes } from 'react-router-dom'

import { FC } from '@publica/ui-common-utils'

import { LedgerAssetTypeIndex } from './LedgerAssetTypeIndex'

type LedgerAssetTypesProps = {
    ledger: { id: string }
}

export const LedgerAssetTypes: FC<LedgerAssetTypesProps> = ({ ledger }) => {
    const ledgerAssetTypeIndex = useMemo(() => <LedgerAssetTypeIndex ledger={ledger} />, [ledger])

    return (
        <Routes>
            <Route path="assetTypes" element={ledgerAssetTypeIndex} />
        </Routes>
    )
}
