import { InternalRefetchQueriesInclude } from '@apollo/client'
import { useCallback } from 'react'

import { FC } from '@publica/ui-common-utils'
import { ControlledForm, FormValuesWithRequired, useConnectControlledForm } from '@publica/ui-web-utils'

import {
    LedgerAccountType,
    PledgeLedgerTransactionCreateInput,
    useCreatePledgeLedgerTransactionMutation,
} from '../../../../../../data'
import { DebitOnlyTransactionForm, DebitOnlyTransactionFormValues } from '../components'

type PledgeFormProps = {
    ledgerId: string
    form: ControlledForm<string | undefined, PledgeFormValues, PledgeFormWithRequiredValues>
    refetchQueries?: InternalRefetchQueriesInclude
}

type PledgeFormValues = DebitOnlyTransactionFormValues
type PledgeFormWithRequiredValues = FormValuesWithRequired<
    PledgeFormValues,
    'debitorAccount' | 'occurredAt' | 'amount' | 'assetType'
>

export const PledgeForm: FC<PledgeFormProps> = ({ ledgerId, form, refetchQueries }) => {
    const [createPledgeLedgerTransaction, { loading }] = useCreatePledgeLedgerTransactionMutation()

    const submit = useCallback(
        async (values: PledgeFormWithRequiredValues): Promise<string | undefined> => {
            const { occurredAt, comment, amount, debitorAccount, assetType } = values

            const transaction: PledgeLedgerTransactionCreateInput = {
                context: {
                    occurredAt,
                    comment,
                },
                amount,
                debitorAccountId: debitorAccount.id,
                assetType: {
                    assetTypeId: assetType.assetTypeId,
                    dismemberment: 'dismemberment' in assetType ? assetType.dismemberment : undefined,
                },
            }

            return createPledgeLedgerTransaction({
                variables: {
                    ledgerId,
                    transaction,
                },
                refetchQueries,
            }).then(({ data }) => data?.createPledgeLedgerTransaction.id)
        },
        [createPledgeLedgerTransaction, ledgerId, refetchQueries]
    )

    useConnectControlledForm(form, submit, loading)

    return (
        <DebitOnlyTransactionForm ledgerAccountTypes={pledgeLedgerAccountTypes} ledgerId={ledgerId} form={form.form} />
    )
}

const pledgeLedgerAccountTypes: LedgerAccountType[] = ['PEA', 'PEA_PME', 'STANDARD']
