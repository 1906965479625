import { ColumnType } from 'antd/lib/table'
import sortBy from 'lodash/sortBy'
import { useCallback, useMemo } from 'react'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { FilterTable } from '@publica/ui-web-components'

type LedgerAssetType = {
    id: string
    name: string
}

type LedgerAssetTypeTableProps = {
    loading?: boolean
    ledgerAssetTypes: LedgerAssetType[]
}

export const LedgerAssetTypeTable: FC<LedgerAssetTypeTableProps> = ({ ledgerAssetTypes, loading = false }) => {
    const { t } = useLedgerAssetTypeTranslation()

    const title = useCallback(() => t('assetTypes'), [t])

    const columns = useMemo<ColumnType<LedgerAssetType>[]>(
        () => [
            {
                render: (_, ledgerAssetType) => ledgerAssetType.name,
            },
        ],
        []
    )
    const sortedLedgerAssetTypes = useMemo(
        () => sortBy(ledgerAssetTypes, assetType => assetType.name),
        [ledgerAssetTypes]
    )

    return (
        <FilterTable
            showHeader={false}
            title={title}
            columns={columns}
            dataSource={sortedLedgerAssetTypes}
            loading={loading}
        />
    )
}

const useLedgerAssetTypeTranslation = createUseTranslation({
    FR: {
        assetTypes: 'Actifs',
    },
    EN: {
        assetTypes: 'Asset types',
    },
})
