import '@publica/ui-common-polyfill'
import { boot } from '@publica/ui-web-boot'

import App from './app/app'
import { Login, authStateBuilder } from './auth'

boot(<App />, {
    auth: {
        stateBuilder: authStateBuilder,
        login: <Login />,
    },
})
